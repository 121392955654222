.thead{
    border-bottom: 1px solid #BCC2CE !important;
    padding: 20px 0 !important;
}
.tableHeaderCell {
    @apply  leading-tight uppercase tracking-wide py-4 px-4;
    border-bottom: 1px solid #E9EDF5;
    color: #464F60;
    font-size: 12px;
}

/*@media (min-width: 800px) {*/
    .tableHeaderCell{
        background: inherit !important;
    }
/*}*/
.cellInner {
    @apply flex items-center justify-between;
}
.textCenterFullWidth {
    @apply w-full text-center;
}
.sortIconContainer {
    @apply inline-block;
}
.sortUpIcon {
    @apply w-4 h-4 text-gray-400;
}
.sortDownIcon {
    @apply w-4 h-4 text-gray-400;
}
.sortIcon {
    @apply opacity-0 group-hover:opacity-100 w-4 h-4 text-gray-400;;
}