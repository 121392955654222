.mt-ui-btn {
    height: 50px !important;
    border-radius: 8px !important;
    border: solid 1px #0369A1;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    color:#fff;
    min-width: 120px;
}

.mt-ui-btn:hover {
 cursor: pointer;
}
.disabled-button {
    opacity: 0.5;
    cursor: not-allowed;
}

.button-container{
    min-width:100px;
}

@media (max-width: 1000px) {
    .mt-ui-btn {
        width: 100%;
    }
    .button-container{
        width:100%;
    }
}