.basic-input{
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background: #FFF;
    padding: 16px;
    @apply transition-all duration-500;
}
.basic-input:focus{
    outline: none;
    }
.in-error{
    border: 1px solid #dc2626;
}
.in-normal{
    border: 1px solid #EAEAEA;
}
.in-normal:focus{
    border: 1px solid #0369A1;
}
@media (min-width: 1024px) {
    font-size: 0.875rem;
}
.in-error-message{
    font-size: 12px;
    font-weight: 500;
    color: #dc2626;
    height: 15px;
   @apply transition-all duration-500;
}
.text-field-container{
    flex-grow:1;
    display:flex;
    flex-direction:column;
    width: 100%;
}