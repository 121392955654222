.cardContainer{
    border-radius: 6px;
    background-color:  #fff;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
    width: auto;
    @apply flex justify-between gap-2 px-4 py-4 cursor-pointer transition-all duration-200 ;
}
.cardContainer:hover {
    transform: scale(1.03);
    background-color: #e8f4fc;
    box-shadow: 0px 4px 20px 2px rgba(0, 0, 0, 0.1);
}

.cardTitle{
    color: #374151;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}
.cardContainer:hover  .cardTitle{
    color: #0369A1 !important;
}
.cardDescription{
    color: #8692A6;
    font-size: 12px;
    font-weight: 400;
    cursor: pointer;
}
.cardContentContainer{
    @apply flex flex-col justify-center gap-2;
}
.iconContainer{
    @apply px-6 py-3 flex items-center justify-center
}
.processCardContainer{
    border-radius: 5px;
    border: 1px solid #C5CEE0;
    box-shadow: 0px 2px 14px 1px rgba(0, 0, 0, 0.06);
    @apply flex flex-col gap-4 px-4 py-4 bg-white
}
.processCardTitleContainer{
    color: #1F2937;
    font-size: 15px;
    font-weight: 600;
  @apply flex  justify-between gap-3
}
.processCardDescription{
    color: #1F2937;
    font-size: 15px;
    font-weight: 400;
}
.cardContainer:hover .cardArrowContainer{
    @apply flex flex-col justify-center items-end  ;
}
.cardArrowContainer{
   display: none;
}
.elipsisText{
    overflow-x:hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}
