.articleContent{
    @apply px-16
}
@media (max-width: 801px) {
    .articleContent{
        @apply !px-6
    }
    .articleMenu{
        color:  black !important;
    }
}
.articleMenu{
    color:  #0369A1;
    font-size: 30px;
    font-style: normal;
    font-weight: 600;
    text-align: center;
    @apply py-8 px-5
}
.articleTitle{
    color:  #374151;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    width: fit-content;
}
.articleTitleLine{
    width: 100%;
    height: 1px;
    background: #D1D1D6;
    @apply my-2
}
.contentStyle{
    @apply py-12;
    color: #374151;
    line-height: 190%;
}
.contentHeader{
    display: flex;
    @apply gap-3 py-3
}
.contentHeaderText{
    color: #374151;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0.5px;
}
.pagetitleContainerRead{
    display: flex;
    align-items: center;
}

@media (max-width: 800px) {
    .titleMobileResponsive{
        @apply mx-auto;
    }
}