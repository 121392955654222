@import "https://fonts.googleapis.com/css2?family=Tajawal&family=Inter&family=Rubik&display=swap";

.landing-page-profil-details{
    position: absolute;
    left: -100%;
    top: 0;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
}

.landing-page-profil-details>div:first-child{
    width: 400px;
    background-color: #0369A1;
}
.landing-page-profil-details>div:last-child{
    width: 100%;
    background-color: #fff;
}
.landing-page-first-section>div:first-child{
    width: 200px;
    height: 200px;
    margin: 40px auto 10px auto;
}
.landing-page-first-section>div:last-child{
    width: fit-content;
    margin: auto;
    font-size: 16px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #fff;
}
.landing-page-second-section li{
    margin: 10px;
}
.landing-page-second-section {
    height: 100%;
    overflow: auto;
}

.landing-page-second-section>span{
    font-size: 28px;
    margin: 10px;
    font-weight: 600;
}

.landing-page-profil-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}

.landing-page-profil-item>img{
    height: 100px;
}
.landing-page-profil-item>span{
    font-size: 16px;
    letter-spacing: 1.5px;
    width: 200px;
    text-transform: uppercase;
    text-align: center;
    color: white;
    margin: 10px;
}

.landing-page-profil{
    margin: 15px;
}
.landing-page-title-profils{
    color : white;
    font-size: 30px;
    font-weight: 500;
    width: fit-content;
    margin: 15px auto;
}
.landing-page-profils{
    width: 100%;
    position: relative;
    background-color: #0369A1;
    padding: 10px 0;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

.landing-page-profils>div{
    display: flex;
    justify-content: center;
    margin: 25px auto 10px auto;
}
.landing-page-first-section>img{
    display: block;
    height: 150px;
    margin: 50px auto 20px;
}


.landing-page-first-paragraph>div:last-child{
    display: flex;
    justify-content: center;
    flex-wrap: wrap-reverse;
    margin: 20px auto;
    padding: 0 20px;
}
.landing-page-first-paragraph-desc{
    display: flex;
    gap: 10px;
}
@media (max-width: 800px) {
    .landing-page-first-paragraph-desc {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
    .landing-page-first-paragraph-desc > div {
       min-width: 240px;
    }
}

/********************************  landing-page-news  ********************************/

.landing-page-news>div:last-child{
    text-align: center;
}
.landing-page-new-link{
display: flex;
justify-content: flex-end;
}
.landing-page-new-link>a{
    text-decoration: none;
    color: #374151;
    width: fit-content;
    transition: 0.4s letter-spacing;
    font-weight: 600;
    font-size: 14px;
}
.landing-page-new-link>a:hover{
    letter-spacing: 0.7px;
}
.landing-page-new-background>div>button{
    background: transparent;
    padding: 2px 30px;
    border-radius: 40px;
    filter: opacity(0.7);
    box-shadow: none;
    font-size: 12px;
}
.landing-page-new-background>div{
    position: absolute;
    bottom: 10px;
    right: 10px;
}
.landing-page-new-background{
    position: relative;
    height: 200px;
}

.landing-page-new-background>img,
.landing-page-new-background>video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 15px 15px 0 0;
}


.landing-page-new>div:first-child>div:nth-child(2){
    font-size: 18px;
    font-weight: 500;
    line-height: 130%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding: 7% 3%;
    color: #374151;
}
.landing-page-new>div>p{
    color: #AAA;
}
.landing-page-new>div:first-child>div:last-child>a{
    width: fit-content;
    display: flex;
    margin-left: auto;
}
.landing-page-new{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    box-shadow: 0 2px 5px 0px #ccc ;
    border-radius: 15px;
    padding-bottom: 20px;
}
.landing-page-news-container{
    display: grid;
    grid-template-columns: repeat(3, 300px);;
    gap: 50px;
    width: fit-content;
    margin: auto;
}

.landing-page-news-scroll-container{
    overflow-x: auto;
    padding: 0 20px 20px;
    
}

.landing-page-news{
    box-sizing: border-box;
    margin: auto;
}
.landing-page-new>div>*:not(.landing-page-new-background){
    text-align: justify;
    margin: 10px 20px;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-size: 14px;
    font-weight: 400;
    line-height: 130%;
}

.sencond-layer-layout{
    display: flex;
    flex-direction: column;
    max-width: 1100px;
    margin: auto;
}
.sencond-layer-layout-description{
    color: #6B7280;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.sencond-layer-layout-title{
    color : #0369A1 ; 
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 150%;
}

.aut-type-icon-container{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 10px 20px;
}
.aut-type-example-title{
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 27px;
}

.aut-type-example-item{
    display: flex;
    flex-direction: column;
    margin: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
}

.auts-types{
    display: flex;
    justify-content: space-between;
    padding: 20px;
}
.aut-type-icon-container{
    padding: 20px;
    border-radius: 10px;
    border: 2px solid #F3F4F6;
}
.aut-type-icon-container>img{
    width: 80px;
    margin: auto;
}
.aut-type-icon-container>div{
    width: fit-content;
    margin: auto;
    color: #3C4D64;
}
.aut-type{
    width: 400px;
}
.aut-type-example-item, .aut-type-example-title{
    color: #4B5563;
    margin: 20px;
}

.landing-page-arrow-right{
    display: flex;
    align-items: center;
    gap: 5px;
}
.landing-page-arrow-right>img{
    margin-top: 3px;
}
.landing-page-arrow-right>span{
    border-bottom: 1px solid #0369A1;;
}



/*************** landing-page-step-profil ***************/

/* .landing-page-steps>div{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 25px;
} */


.landing-page-step-icon>span{
    color: #0369A1;
    font-size: 35px;
    font-weight: 300;
}


.landing-page-step-profil {
    background-color: #F5FBFF;
    padding: 2% 0;
}
.landing-page-step-profil .title{
    color: #252729!important;
}
.landing-page-new-background .global-btn{
    opacity: 0.6;
    box-shadow: 0 0 1px #5e5858;
}
.landing-page-steps {
    display: flex;
    width: fit-content;
    gap: 20px;
    justify-content: center;
    margin: 30px auto;
}

@media screen and ( max-width : 1000px ) {
    .landing-page-steps{
        flex-direction: column;
         align-items: center;
    }
}


.landing-page-step {
    flex: 0 1;
    display: flex;
    gap : 10px ;
    align-items: flex-start;
}
.landing-page-step>div:first-child {
    margin-top: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #0369A1;
    border-radius: 8px;
    width: 40px;
    height: 35px;
    padding-bottom: 3px;
}
.landing-page-step>div:first-child>span {
    color: #fff;
    font-size: 26px;
    height: fit-content;
    width: fit-content;
}
.landing-page-step>div:last-child{
    display: flex;
    flex-direction: column;
    width: 250px;
    gap: 10px;
    min-height: 65px;
    justify-content: space-between;
}
.landing-page-step>div:last-child>div {
    color : #252729 ;
    font-weight: 500;
    font-size: 15px;
}
.landing-page-step>div:last-child>a {
    color: #0369A1;
    text-decoration: none;
    display: flex;
    gap: 10px;
}   

/************************* landing-page-functionalities ***********************/
.landing-page-functionalities{
    display: flex;
    flex-direction: column;
    margin: auto;
}

.landing-page-functionalities-items{
    display: flex;
    justify-content: center;
    gap: 50px;
    margin: 20px 0;
}

@media screen and ( max-width : 1000px ) {
    .landing-page-functionalities-items{
        gap: 25px;
    }
    
}

/* .landing-page-functionality::after{
        display: inline-block;
        width: 30px;
        height: 20px;
    margin-top: 10px;
    content: url( "./footer/triangle.svg" );

} */

.landing-page-functionality>button{
    display: flex;
    flex-direction: column;
    font-size: 18px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    gap: 20px;
    border-radius: 5px;
    padding: 0;
    width: 200px;
    height: 200px;
}
@media screen and ( max-width : 1000px ) {
    .landing-page-functionality>button{
        width: 170px;
        height: 170px;
    }
}
@media screen and ( max-width : 820px ) {
    .landing-page-functionality>button{
        width: 130px;
        height: 130px;
    }
}


.landing-page-functionality-selected-button{
    border: 2px solid #0369A1;
    color: #fff;
    font-size: 20px;
    box-shadow : 0 10px 10px 1px #ccc;
    background-color: #0369A1;
    width: 210px !important;
    height: 210px !important;
}
@media screen and ( max-width : 900px ) {
    .landing-page-functionality-selected-button{
        font-size: 14px;
    }
}
.landing-page-functionality-none-selected-button{
    border: 2px solid #0369A1;
    color: #0369A1;
    background-color: #F9FAFB;
}


.landing-page-functionality-selected-button>.landing-page-functionality-img-container{
    background-color: #FFFFFF;
    transition: 0.16s height , 0.16s width , 0.16s background-color;
    width: 120px;
    height: 120px;
}
@media screen and ( max-width : 1000px ) {
    .landing-page-functionality-selected-button>.landing-page-functionality-img-container{
        width: 90px;
        height: 90px;
    }
}
@media screen and ( max-width : 820px ) {
    .landing-page-functionality-selected-button>.landing-page-functionality-img-container{
        width: 75px;
        height: 75px;
    }
}
.landing-page-functionality-none-selected-button>.landing-page-functionality-img-container{
    background-color: #D2EAFE;
    width: 100px;
    height: 100px;
    transition: 0.16s height , 0.16s width , 0.16s background-color;
}
@media screen and ( max-width : 1000px ) {
    .landing-page-functionality-none-selected-button>.landing-page-functionality-img-container{
        width: 80px;
        height: 80px;
    }
}
@media screen and ( max-width : 820px ) {
    .landing-page-functionality-none-selected-button>.landing-page-functionality-img-container{
        width: 67px;
        height: 67px;
    }
}

.landing-page-functionality-img>img{
    height: 100%;
}

.landing-page-functionality{
    display: flex ;
    flex-direction: column ;
    justify-content: center;
    align-items: center;
    position: relative;
}
.landing-page-functionality>span{
    transform: rotateX(50deg);
    margin-right: 30px;
    position: absolute;
    bottom: -60px;
    color: #F5FBFF;
    font-size: 51px;
}
.landing-page-functionality-img-container{
    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
}

.landing-page-functionality-description{
    width: fit-content;
    margin: auto;
    display: flex;
    flex-direction: column;
    padding: 10px;
    gap: 0;
    border-radius: 50px;
    background: #F5FBFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

}



.landing-page-functionality-description>div:nth-child(2){
    display: flex;
}
.landing-page-functionality-description>div:first-child{
    display: grid;
    height: 60px;
    grid-template-columns: 360px 360px;
    justify-content: center;
    gap: 60px;
}

@media screen and (max-width : 900px) {
    .landing-page-functionality-description>div:first-child{
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        gap: 0;
        align-items: center;
        height: 50px;
    }
}

.landing-page-functionality-description-left-side{
    max-width: 500px;
}
.landing-page-functionality-description-item{
    display:flex;
    gap: 5px;
}

.landing-page-functionality-message{
    position: relative;
}

@media screen and (max-width : 900px) {
    .landing-page-functionality-message{
        position: static;
        text-align: center;
    }
}

.landing-page-functionality-message>span>img{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
}
.functionality-message{
    font-size: 17px;
    font-weight: 700;
    color : #0369A1;
;
}
@media screen and (max-width : 900px) {
    .landing-page-functionality-message>span>img{
        display: none;
    }
}

.landing-page-profils-mini-container-top .landing-page-functionality-message>span:not(.to-unset-font){
    display: flex;
    flex-direction: column;
}

.landing-page-functionality-message>span:not(.to-unset-font){
    position: absolute;
    top: 18px;
    z-index: 1;
}
@media screen and (max-width : 900px) {
    .landing-page-functionality-message>span:not(.to-unset-font){
        position: static;
    }
}

.landing-page-functionality-description li{
    font-weight: 500;
    margin: 10px 0;
    font-size: 16px;
    color: #1F2937;
    line-height: 150%;
}
.landing-page-functionality-description-right-side{
    max-width: 500px;

}
.landing-page-functionality-description-center-side{
    margin-top: -25px;
}
@media screen and ( max-width : 900px ) {
    .landing-page-functionality-description-center-side{
        margin-top: 40px;
    }
}

.landing-page-functionality-description-center-side>.to-unset-font>img{
    height: 250px;
    min-width: 173px;
}

.landing-page-functionality-description-bottom-buttons{
    margin: 20px auto;
    display: flex;
    justify-content: center;
    gap: 10px;

}
.landing-page-functionality-description-bottom{
    margin: auto;
    width: 80%;
}

/****************** landing-page-consult ******************/

.topcomponent-item-img>.to-unset-font{
    font-size: 40px;
    font-weight: 300;
    display: inline-block;
    width: 100%;
    margin-bottom: 7%;
}

.landingpage-consult .topcomponent-item-img{
    text-align: center;
}
.landingpage-consult .topcomponent-item-img>.to-unset-font>img{
    max-height: 160px;
    width: 100%;
    object-fit: contain;
}
@media screen and (max-width : 900px) {
    .landingpage-consult .topcomponent-item-img>.to-unset-font>img{
        max-height: 100px;
    }
}

.landingpage-consult{
    color :#374151 ;
    font-size: 18px;
    font-weight: 500;
    width:86%;
    margin: auto;
}

.topcomponent-item-title{
    color :#374151 ;
    font-size: 18px;
    font-weight: 600;
    height: 60px;
    line-height: 130%;
}

.landingpage-consult>.title-paragraph{
    color :#252729 ;
}
@media screen and ( max-width : 900px ) {
    .landingpage-consult-mobile{
        display: block!important;
    }
    .landingpage-consult{
        display: none;
    }
}

.landingpage-consult-mobile>.title-paragraph{
    color: #fff;
}

.landingpage-consult-mobile .global-component-link{
    width: fit-content;
    text-align: center;
    margin: 15px auto;
}
.consult-item-mobile-title{
    font-size: 20px;
    font-weight: 700;
    padding: 10px;
    text-align: center;
    color: #374151 ;
}
.consult-item-mobile-description{
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    padding: 10px;
    color: #4B5563;
}
.consult-item-mobile>.global-component-link{
    padding: 10px;
}
.consult-item-mobile-img .to-unset-font{
    width: 100%;
    max-width: 60%;
}
.consult-item-mobile-img{
    display: flex;
    justify-content: center;}

.consult-item-mobile{
    display: flex;
    flex-direction: column;
    padding: 2% 5%;
}
.consult-item-conatainer-part2{
    display: flex;
    flex-direction: column;
    gap: 14px;
}


.landingpage-consult-mobile>.ant-tabs.ant-tabs-top.ant-tabs-card{
    background: #fff;
    max-width: 500px;
    width: 100%;
    margin: 50px auto;
    border-radius: 0 0 30px 30px;
    overflow: hidden;
}
.landingpage-consult-mobile .ant-tabs-tab{
    border-color: transparent;
}
.landingpage-consult-mobile .ant-tabs-tab-active .actived-consult-span{
    display: unset;
}
.landingpage-consult-mobile .ant-tabs-tab-active>div>span{
    display: grid;
    align-items: center;
    grid-template-columns: 20px 1fr;
    gap: 10px;
}
.landingpage-consult-mobile .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab{
    padding: 8px!important;
    background: rgba(255, 255, 255, 0.13);
    border: none;
    color: #fff;
}
.landingpage-consult-mobile .ant-tabs-tab-active{
    background-color: #fff!important;
}
.landingpage-consult-mobile .ant-tabs-tab-active .actived-consult-span{
    color: #0369A1;
}
.landingpage-consult-mobile .ant-tabs-tab-active .to-unset-font{
    color: #0369A1;
}
.landingpage-consult-mobile .ant-tabs-nav-more{
    display: none!important;
}

.landingpage-consult-mobile .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-wrap{
    background: #0369A1 ;
    display: block;
}

.landingpage-consult-mobile{
    background: #0369A1;
    padding: 10px;
    display: none;
}
.landingpage-consult-mobile .ant-tabs-tab:not(.ant-tabs-tab-active){
    width: 50px;
    flex-grow: 1;
}
.landingpage-consult-mobile .ant-tabs-tab-active{
    flex-grow: 2;
}

.landingpage-consult-mobile .ant-tabs-tab-active{
    padding: 10px;
}
.actived-consult-span{
    text-align: left;
    white-space: pre-wrap;
    line-height: 15px;
    font-size: 14px;
    display: none;
}

.landingpage-consult-mobile .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab, .ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab{
    font-size: 18px;
    justify-content: center;
}

.landingpage-consult-mobile .ant-tabs-tab{
    border-radius: 10px 10px 0 0!important;
}
.landingpage-consult-mobile .ant-tabs > .ant-tabs-nav .ant-tabs-nav-list, .ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-list{
    display: flex;
}

/****************** landing-page-accompaniments ******************/


.landingpage-accompaniments .topcomponent-items>div{
    border-left: 2px solid #D4E3EB;
}

.landingpage-accompaniments .topcomponent-items>div:nth-child(1),
.landingpage-accompaniments .topcomponent-items>div:nth-child(2),
.landingpage-accompaniments .topcomponent-items>div:nth-child(3),
.landingpage-accompaniments .topcomponent-items>div:nth-child(4) {
    border-left: transparent;
}
.landingpage-accompaniments{
    color :#252729 ;
    font-style: normal;
    font-weight: 600;
}
@media (max-width: 800px) {

    .landingpage-accompaniments {
        background: #F5FBFF;
        margin: 3% 0 0;
    }
}
@media (min-width: 801px) {
  .landingpage-accompaniments{
            background: white;
            margin: 3% 7% 0;
  }
}

/************************  topcomponent-call  ***************************/

.topcomponent-call{
    margin: 50px 0 0px;
}


.landingpage-accompaniments-mini:hover .global-component-link>a>.to-unset-font{
        left: 8px;
        transition: 0.5s left;
}


/***************** stepAuth ********************/
.landing-page-step-item:hover>.global-component-link>a>.to-unset-font{
    left: 8px;
    transition: 0.5s left;
}
.landing-page-step-item:hover{
    box-shadow: 0 2px 10px 0px #ccc;
    transition: .4s box-shadow;
}
.landing-page-step-item{
    flex-direction: column;
    max-width: 19%;
    min-width: 200px;
    border-radius: 12px;
    box-shadow: 0px 8px 12px 0px rgba(0, 0, 0, 0.04);
    transition: .2s box-shadow;
    background: #FFFFFF;
    padding: 5% 2% 5px 2%;
}

@media screen and (max-width : 1200px){
    .landing-page-step-item{
    }
}
.landing-page-step-img{
    text-align: center;
}
.landing-page-step-img>img{
    height: 38px;
    margin: 5px auto;
}
.landing-page-step-title{
    color: #4B5563;
    font-weight: 500;
    text-align: center;
    margin: 20px auto;
    font-size: 18px;
}

.landing-page-step-item>div{
    text-align: center;
}



.landingpage-accompaniments-mini{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    border-radius: 40px;
    margin: 20px auto;
    box-shadow: 0px 1px 4px 0px #ccc;
    transition: 0.5s box-shadow;
    padding: 20px;
    background: white;
    min-height: 150px;
}
.landingpage-accompaniments-mini:hover{
    box-shadow: 0px 1px 10px 1px #ccc;
}

.landingpage-accompaniments-mini-icon{
    padding: 10px;
}

.landingpage-accompaniments-mini-content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
}
.landingpage-accompaniments-mini-content>div:nth-child(1){
    color: #252729;
    font-weight: 700;
    font-size: 20px;
}
.landingpage-accompaniments-mini-content>div:nth-child(2){
    font-size: 14px;
}
.landingpage-accompaniments-mini-container-items{
    padding: 10px 20px;
}

.landingpage-accompaniments-mini-container-items .to-unset-font{
    font-size: 34px;
}


@media screen and ( max-width : 800px ) {
    .landingpage-accompaniments-content-large{
        display: none;
    }
    .landingpage-accompaniments-content-mini{
        display: unset;
    }
}
@media screen and ( min-width : 801px ) {
    .landingpage-accompaniments-content-large{
        display: unset;
    }
    .landingpage-accompaniments-content-mini{
        display: none;
    }
}


.landingpage-profil-mini-title{
    cursor: pointer;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
}
.landing-page-profil-mini-img>.to-unset-font>img{
    height: 80px;
    width: 80px;
    border-radius: 50%;
}
.landing-page-profil-mini-img{
    height: 70px;
    width: 70px;
    border-radius: 50%;
    overflow: hidden;
    background-color: #fff;
}

.landing-page-profil-mini-first-part{
    display: grid;
    grid-template-columns: 70px auto;
    align-items: center;
    gap: 20px;
    color: #fff;
}
.landing-page-profil-mini-icon>span.to-unset-font{
    font-size: 16px;
    font-weight: 300;
    transition: 0.6s transform;
    transform: rotateZ(0deg);
}
.landing-page-profils-mini-container{
    display: none;
}
.landing-page-profils-mini-container-top{
    padding: 20px 0px;
    background-color: #075985;

}

@media screen and ( max-width : 800px ) {
    .landing-page-profils-large-container,.profils-large{
        display: none;
    }
    .landing-page-profils-mini-container,.profils-mobile{
        display: unset;
    }
}




.landing-page-profils-mini-container-top .title-paragraph{
    color: #fff;
}
.landing-page-profils-mini-container-top *:not(span.to-unset-font){
}



.selected span.to-unset-font{
    transform: rotateZ(180deg);
}

.selected *{
    color: #075985!important;
}
.selected .landing-page-profil-mini-as-ican{
    font-weight: 600;
}

.landingpage-profil-mini-title:not( .selected .landingpage-profil-mini-title ) {
    border-radius: 15px;
    color: #fff;
    padding: 10px;
    box-shadow: 0 1px 8px -6px #FFF;
}
.selected {
    border: 1px solid transparent;
    border-radius: 15px;
    color: #075985;
    background: #F5FBFF;
    padding: 10px;
    box-shadow: 0 1px 18px -5px #252729;
}
.menu-categorie-list-items-globalcomponent{
    padding: 5px;
}
.selected .landingpage-profil-mini-title .landing-page-profil-mini-img>.to-unset-font>img {
    background-color: #D2EAFE;
}

.landingpage-profil-mini-description>li{
    font-size: 14px;
    list-style-type: '\2713';
    padding: 6px 0;
}

.landingpage-profil-mini-description>li>span{
    margin-left: 8px;
}

.landingpage-profil-mini-description{
    padding: 0;
    margin: 0 20px;
    list-style-position: outside
}


.articles .card {
    border-radius:0;
    margin:10px auto;
}
.articles .card-title {
	text-transform: uppercase;
	color: #E44424;
}

.articles .card-text {
	margin-top:10px;
	margin-bottom: 10px;
	background-color:#FFFFFF;
	color:#000000;
}
.articles a.btn, a.btn:visited {
    color:#333333;
}
.articles hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
/* src/BarGraph.css */
.bar-graph {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 10px;
    
  }
  .x-axis {
    position: absolute;
    bottom: 6px;
    left: 0;
    width: 100%;
    height: 27px;
    background-color: #818181;
    z-index:-1
  }
  
  .bar {
    position: relative;
    width: 20px;
    background-color: #3498db;
    margin-bottom: 10px;
  }
  .label{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #818181;
    background-color: white;
    border-radius: 45%;
    font-size: 12px;
  }
  /* .label {
    position: absolute;
    bottom: -15px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 12px;
  } */

  .rotate-y-180{
      transform: rotateY(180deg);
  }

  .img-fixed{
    max-width: 100%;
    max-height: 100%;
   }

.blue-color{
   color: #0369A1;
}
