.backend-sider{
    display: flex;
    flex-direction: column;
    gap: 20px;

}

.backend-logo-rokhas{
    margin: 20px;
}

.backend-user-connected{
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: #6768a6;
    position: relative;
}
.backend-user-connected a{
}

.backend-user-connected img{
    display: inline-block;
    width: 100%;
    height: 100%;
}

.backend-user-connected span{
    position: absolute;
    bottom: 4px;
    right: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: gold;
    border: 1px #fff solid;
    box-shadow: 0 0 1px 0px #ccc;
}

.backend-header-content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}


.backend-header-container .ant-layout-header{
    background-color: #fff!important;
}

.backend-header-container .ant-input-affix-wrapper{
    min-width: 300px;
}
.App>section>section>section{
    min-height: 100vh;
    padding: 0!important;
}

.App>section>section>aside.ant-layout-sider {
    max-width: fit-content!important;
    min-width: 280px!important;
    width: fit-content!important;
    color:#fff!important;
    background-color: #0369A1!important;

    
}
.backend-sider .ant-menu-submenu {
    color: #fff!important;
    /* background-color: #F5FBFF; */
    font-weight: 600;
    font-size: 16px;
}


.backend-sider li>ul.ant-menu-inline{
    color: #fff!important;
}

.backend-sider .ant-menu-item{
    color: #0369A1;
    /* background-color: #F5FBFF; */
    font-weight: 600;
    font-size: 16px;
}
.backend-sider>.ant-menu.ant-menu-root{
    background-color: #0369A1!important;
}

.backend-sider .ant-menu-submenu-open>div {
    background-color: #E0F2FE!important;
    padding: 10px 50px;
}










.backend-all-page{
    display: grid;
    grid-template-columns: 280px auto;
    min-height: 100vh;
}

.backend-sider{
    background-color: #0369A1;
}


.backend-header-container{
    display: grid;
    grid-template-rows: 70px auto;
    height: 100%;    
}
.backend-header-container>*{
    width: 100%;
}

.backend-content{
    background-color: #F8F8F8;
    height: 100%;
}
.backend-header{
    height: 70px;
    width: calc( 100vw - 280px );
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.backend-header-content>div{
    margin-left: 30px;
    margin-right: 30px;
}
.backend-logo-rokhas{
    width: 180px;
}


.backend-menu-item-content{
    display: none;
}
.backend-menu-item-label{
    cursor: pointer;    
}








.backend-menu-aside .backend-menu-item{
    margin: 10px 20px;
}
.backend-menu-aside .backend-menu-item-label{
    color: #fff;
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 30px auto;
    gap: 10px;
    font-size: 16px;
    padding: 10px 20px;
}

.selected-item>.backend-menu-item-content{
    display: block;
}

.selected-item>.backend-menu-item-label{
    font-weight: 600;
}


.backend-menu-aside>.selected-item>.backend-menu-item-label{
    background-color: #E0F2FE;
    color: #0369A1;
    border-radius: 20px;
}

.backend-aside-content-menu-list{
    margin-left: 20px;
}

.backend-aside-content-menu-list-item{
    display: grid;
    justify-content: flex-start;
    grid-template-columns: 30px auto;
    gap: 10px;
    font-size: 15px;
    cursor: pointer;
    margin: 5px 0;
    padding: 10px 20px;
    color: #fff;
    border-radius: 20px;
}
.backend-aside-content-menu-list-item.selected-item{
    background-color: #075985;
    
    font-weight: 600;
}

.backend-sider .logo-img{
    width: 160px;
}

.backend-header-content .ant-input-affix-wrapper {
    border: none;
    background: #F8F8F8;
    color: #C7CCD7;
}
.backend-header-content .ant-input {
    background: #F8F8F8;

}

.backend-user-connected{
    box-shadow: 0 0 1px #ccc;
    cursor: pointer;
}

.backend-buttons-content-top{
    display: flex;
    gap: 10px;
}

.backend-buttons-content-top button{
    padding: 8px 20px;
    border-radius: 6px;
    box-shadow: 0 0 1px 0px #ccc;
    text-align: center;
    cursor: pointer;
    border: none;
    max-height: 42px;
}

.backend-buttons-content-top .previsualiser{
    background-color: #F5FBFF;
    color: #0369A1;
    display: flex;
    align-items: center;
    gap: 10px;
}

.backend-buttons-content-top .sauvegarder{
    background-color: #0369A1;
    color: #fff;
}

.backend-buttons-content-top .annuler{
    background-color: #F8F8F8;
    color: #C2C2C2;
}

.backend-content-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.backend-content-top>*{
    margin: 20px 30px;
}

.backend-content-top h2{
    color: #0369A1;
}

.backend-content-rest{
    display: grid;
    height: 100%;
    grid-template-columns: 50% 50%;
    grid-template-rows: 100%;
}
.backend-content-rest>.overview{
    border-radius: 20px;
    box-shadow: 0 0 16px -3px #ccc;
    margin: 5px 10px;
    background: #fff;
    overflow: auto;
}
.backend-content-rest>.controle{
    overflow: auto;
    margin: 5px 8px;
}


.container-frontend{
    width: 1290px;
}


.backend-controle-section-title{
    display: flex;
    justify-content: space-between;
    color: #4B5563;
    background-color: #F3F4F6;
    border-radius: 4px;
    box-shadow: 0 0 5px -2px #ccc;
    padding: 8px 20px;
    margin: 0 5px 15px 5px;
}

.backend-controle-section-title>span:first-child{
    font-weight: 600;
}

.backend-controle-section-content{
    padding: 8px 20px;
    margin: 15px 5px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0 0 5px -2px #ccc;
}







.backend-controle-section-content-entry{
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin-bottom: 15px;
    flex-basis: 100%;
}
.backend-controle-section-content-entry label{
    font-weight: 600;
}

.backend-controle-section-content-entry input {
    display: inline-block;
    border-radius: 3px;
    border: 1px solid #ccc;
    height: 30px;
    width: 100%;
}

.backend-controle-section-content-entry textarea {
    display: inline-block;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
}

.backend-container-buttons-controller{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.backend-container-button-controller{
    display: flex;
    align-items: flex-start;
    gap: 10px;
}

.font-weight-600{
    font-weight: 600;
    margin: 8px 0;
}

.xmark{
    display: none;
    margin: 0 3px;
}
.selected-item .xmark{
    display: unset;
}

.selected-item .pen{
    display: none;
}

.selected-item .backend-controle-section-title{
    background-color: #E0F2FE;
    color: #0EA5E9;
}

.backend-buttons-actions{
    display: inline-flex;
    /* max-width: 250px; */
    gap: 10px;
}

.backend-buttons-actions button{
    height: 34px;
    padding: 4px 22px;
    box-shadow: none!important;
}
.backend-container-buttons-actions{
    text-align: right;
}

.apercu-icon>img{
    display: block;
    height: 100%;
    width: 100%;  
}
.apercu-icon{
    font-size: 30px;
    padding: 10px 30px;
    background-color: #E0F2FE;
    box-shadow: 0 0 3px 0 #ccc;
    margin: 10px 5px 5px;
    height: fit-content;
    display: block;
    width: 250px;
    text-align: center;
}


.icon-input-container .global-btn{
    display: flex;
    gap: 10px;
    font-size: 10px;
} 

.icon-input-container{
    display: flex;
    gap: 40px;
    align-items: flex-end;
    justify-content: flex-start;
    position: relative;
    width: 100%;
}

dialog{
    position: fixed;
    z-index: 300;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    box-shadow: 0 0 5px 0px #aaa;
    padding: 30px;
    min-width: 500px;
}

dialog>*{
    margin: 10px 0;
}
.icon-showed-popup{
    text-align: center;
    font-size: 50px;
}

.backend-buttons-actions-container{
    text-align: center;
}

.select-input{
    display: block;
    height: 35px;
    width: 255px;
    border: none;
    cursor: pointer;
    box-shadow: 0 0 2px 0px #ccc;    
}

.to-show{
    display: block;
}

.profil-content-menu>div{
    cursor: pointer;
    padding: 5px;
    text-align: center;
    border-bottom: 3px solid transparent;
}

.profil-content-menu{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    background: #E0F2FE; 
    font-size: 12px;
    margin: 15px 0;
}

.selectedItemContentProfil{
    font-weight: 600;
    border-bottom: 3px solid #4B5563!important;
}

.save-button-setions{
    margin: 0 30px 0 0;
}

.delete-description>span{
    cursor: pointer;
}
.delete-description{
    display: flex;
    align-items: center;
    margin: 5px;
    color: red;
    font-size: 17px;
}
.control-description{
    position: relative;

}
.delete-description::before{
    font-size: 16px;
}
.add-description-icon{
    margin: 0 10px;
    color: #075985;
    cursor: pointer;
    font-size: 18px;
}

.control-description{
    display: grid;
    grid-template-columns: auto 28px;
}


/* .icon-input-container-btn{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
    height: 100%;
}

.icon-input-container-btn button{
    color: transparent;
    background-color: transparent;
    border-color: transparent;
    box-shadow: none;
} */


.btn-save{
    padding: 2px 15px;
}

.cancel-btn-form{
    box-shadow: 0 0 2px 1px #ccc;
    margin: 20px auto 5px;
}

.import-img-icone>span{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #FFF;
    color: #0369A1;
    height: 100%;
    border-radius: 3px;
    border: 1px solid #ccc;
}

.import-img-icone{
    cursor: pointer;
    height: 30px;
    width: 40px;
    position: relative;
}
.import-img-icone>*{
    position: absolute;
    top: 0 ;
    left: 0;
    width: 40px;
}

.popup-inputs-container{
    display: flex;
    align-items: center;
    gap: 5px;
}


.add-image-input-icon{
    opacity: 0;

}
.add-image-input-icon-content{
    display: none;
    margin: 20px 0;
}
.add-image-input-icon:checked~.add-image-input-icon-content{
    display: block!important;
}
.add-image-input-icon:checked~label .add-image-input-icon-chevron{
    transform: rotateZ(180deg);
    transition: 0.2s transform;
}
.add-image-input-icon-content-inputs{
    display: flex;
}
.add-image-input-icon-content-inputs .global-btn-container{
    height: 30px;
    padding: 0;
    margin: 0 0 0 20px;
}
.add-image-input-icon-content-inputs .global-btn{
    border-radius: 0;
    border: 1px solid #ccc;
}