.mapp {
  width: 99%;
  height: 80vh;
  z-index: 0;
  position: relative;
  margin-top: 10px;
  border-radius: 8px; /* Rounded corners */
  overflow: hidden; /* Hide overflow */
}

.mapContainer{
  @apply px-24;
  @apply py-4;
}
/*filter item*/
.searchItemContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filterContainer{
  display: flex;
  align-items: center;
}

.searchContainer{
  display: flex;
  width: 100%;
  gap: 10px;
  @apply py-3;
  @media (max-width: 768px) {
    border: 1px solid  #6B7280;
    border-radius: 6px;
    margin-bottom: 10px;
    gap: 0px !important;
   }
}

.notShow {
  visibility: hidden;
  opacity: 0;
}
.filterItemsContainer{
  display: flex ;
  align-items: center;
  max-height: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  gap: 8px;
  width: 67%;
}
@media (max-width: 768px) {
  .filterItemsContainer{
    flex-direction: column;
    width: 100%;
  }
}
.selectElement{
 width: 100%;
}
.filterItemsContainerAppear{
  max-height: 500px !important;
  @apply pt-4 pb-1
}
.customborderwrapper {
  position: relative;
  display: inline-block;
  padding-bottom: 20px;
}

.customlabel {
  position: absolute;
  top: -10px;
  left:10px;
  background-color: white;
  padding: 0 5px;
  color: #9CA3AF;
}

.customlabel.rtl {
  right: 20px; /* Adjust this as needed for RTL languages */
  left: auto;
}

.customlabel.ltr {
  left: 20px;
  right: auto;
}

.customborder {
  border: 1px solid #9CA3AF;
  padding: 12px; /* Adjust as needed */
  border-radius: 5px; /* Optional, if you want rounded corners */
}
.pageTitle {
  color: #0369A1;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  @media (max-width: 768px) {
  color: #374151;
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
}

.pageTitleDescription {
  display: flex;
  color: #6B7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  @apply py-4;
  @media (max-width: 768px) {
    justify-content: center;
    color: #374151;
    font-family: Tajawal;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 24.2px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}
.descriptionContent{
  @media (max-width: 768px) {
    color: #374151;
    width: 311px;
    text-align: center;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}
.containerstylesearch{
  padding: 50PX 50PX 0PX;
  @media (max-width: 768px) {
    padding: 10PX !important;
    }
}


