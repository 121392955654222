
.selectButton {
    position: relative;
    display: inline-block;
    width: inherit;
}

.selectedOption {
    transition: background-color 0.2s linear;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 6px;
    width: auto;
}
.selectedText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.optionSelected{
    border: 1px solid #fff;
    color: #fff;
    background: #0369A1;
}
.optionSelected > i:hover{
    color: #d0d0d0;
}
.optionNotSelected{
    border: 1px solid #0369A1;
    color: #0369A1;
    background:#F9FAFB;
}
.optionNotSelected > i:hover{
    color: #003a56;
}
.optionError :global{
    border: 1px solid #F87171 !important;
    background: #FFFFFF !important;
    color: #F87171 !important;
    -webkit-animation-name: tilt-shaking;
    -webkit-animation-duration: 0.65s;
    -webkit-animation-timing-function: linear;
}
.optionError > i:hover{
    color: #ff0000 !important;
}

.selectedOptionLabel{
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: #0369A1;
}
.options {
    position: absolute;
    left: 0;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #0369A1;
    border-top: none;
    border-radius: 0 0 4px 4px;
    background: #fff;
    z-index: 20;
    transition: all 0.3s ease-in;
}
.optionsItemContainer{
    @apply py-2 px-2;
}
.optionsItemContainer > :first-child{
    @apply rounded-t-md;
}
.optionsItemContainer > :last-child{
    @apply rounded-b-md;
}

.option {
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}

.option:hover {
    background: #F9FAFB;
}
.notDisplay{
    opacity: 0;
    visibility: hidden;
    max-height: 0;
    transition: all 0.3s ease-in;
}
.searchInputContainer{
    position: sticky;
    top:0;
    border: 1px solid  #fff ;
    border-bottom: 1px dotted #65787d;
    display: flex;
    padding: 0 5px;
    font-size: 16px;
    align-items: center;
}
.searchInputItem{
    border: 0;
    padding:10px;
    transition: background-color 0.3s;
    width: 100%;
    font-size: 16px;
    color: #4B5563;
}
.searchInputItem:focus{
outline: none;
}
.noData{
    padding: 20px;
}

/* form select styles*/

.formOptionSelected{
    border: 0.5px solid  #D1D5DB;
}
.formOptionNotSelected{
    border: 0.5px solid #D1D5DB;
    background:#FFFFFF;
}
.formOptionError{
    @apply border-red-600 ;
}
.formSelectedOption {
    transition: all 0.2s linear;
    gap: 8px;
    cursor: pointer;
    color:  #374151;
    border-radius: 6px;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @apply pb-3.5 pt-5 ;
}
.formLabel{
    position: relative;
    top: 10px;
    @apply mx-2 px-2 z-10 w-fit bg-white;
    color:rgba(34, 51, 84, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: all 0.1s ease;
}

.formSelectedText{
    color: #374151;
    font-size: 16px;
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: inherit;
    @apply px-3;
}

@keyframes :global(tilt-shaking) {
    0% { transform: translateX(0) }
    25% { transform: translateX(5px) }
    50% { transform: translateX(-5px) }
    75% { transform: translateX(5px) }
    100% { transform: translateX(0) }
}
.activeFormLabel{
    color: #0369A1 !important;
    transform: scale(1.08);
    font-weight: 500 !important;
}
.disableFormLabel{
  visibility: hidden;
}
.openFormSelect{
    border: 0.5px solid #0369A1 ;
}
.activeOptions{
    background-color: #ecf3f6 !important;
}
.errorMessage{
    color:  #F87171;
    font-size: 12px;
    font-weight: 400;
    height: 15px;
    @apply py-1.5;
}
.disabled{
    border: 1px solid #fff;
    color: #fff;
    background: rgb(172, 172, 172);
}
.disabled > i:hover{
    color: white;
}