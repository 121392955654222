.leaflet-popup-content {
  width: 250px !important; 
}
.ant-picker-input input{
color: #075985 !important;
}
@media (max-width: 768px) {
  .ant-picker-range {
    width: 100% !important;
  }

  .ant-picker-dropdown {
    width: 100% !important;  /* Full width for the dropdown as well */
    left: 0 !important;      /* Align it to the left edge */
  }
  .responsiveStyle{
  align-items: center !important;
  padding: 0px 0px 0px !important;
  }
}

.label-data-item {
  color: #0E5B9B;
  font-size: 12px; 
  font-family: Tajawal;
  font-weight: 700; 
  line-height: 20px;
}
.label-adresses{
  font-family: 'Tajawal';
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    color: #6B7280;
}
.data-item{
  display: flex;
  flex-direction: row;
  gap: 8px; 
  max-width: 250px; 
  /* justify-content: center;
*/} 
.data-title{
  display: flex;
  flex-direction: row;
  justify-content: center;
} 
.valeur-reference{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1D5A8D; 
  font-family: Tajawal;
  font-size: 14px;
  font-weight: 700;
  line-height: 20px;
  text-align: right;
  
}
.data-itemReg {
  display: flex;
  flex-direction: row;
  gap: 4px; 
  max-width: 215px;
  justify-content: center;
  overflow: hidden; 
  text-overflow: ellipsis; 
  transition: all 0.3s ease;
}


.popup {
  border-radius: 6px; 
}

.popup-content {
  display: flex;
  flex-direction: column;
  padding: 6px; 
  gap: 2px; 
}
.popup-content-reg {
  display: flex;
  padding-top: 8px;
  padding-right: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  margin: 8px;
  gap: 8px;
  justify-content: space-around;
  cursor: pointer;
  background-color: #f9f9f9; 
  border-radius: 8px; 
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.popup-content-reg:hover {
  transform: scale(1.05); 
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2); 
  background-color: #e0f7fa; 
}


.prefixinfo {
  width: 100%; 
}


.valeurReg {
  width: 100%; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #1D5A8D; 
  font-weight: inherit;
  justify-content: center;
  font-size: 13px; 
}
.valeur{
  width: 100%; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Tajawal;
  color: #0E5B9B; 
  font-weight: 400;
  justify-content: center;
  font-size: 12px; 
  line-height: 18px;
}


.valeur:hover {
  white-space: normal; 
}
.valeurReg:hover {
  white-space: normal; 
}

.mapp {
  width: 100%;
  height: 100%;
  z-index: 0;
  position: relative;
}


.zoom-button {
  width: 20px;
  height: 20px;
    background-image: url('/src/asset/images/location.png'); /* Replace with actual image path */
    background-repeat: no-repeat;
    background-position: center;
    background-size: 22px 21px; /* Adjust the size of the image */
    padding-left: 2px; /* Make space for text next to the image */
}

.zoom-button:hover {

}

.zoom-button:active {
}

.leaflet-container a.leaflet-popup-close-button{
  top: 4px !important;
  right: 8px !important;
  color: #616060 !important;
  font: 21px / 24px Tahoma, Verdana, sans-serif;
}
.leaflet-popup-content-wrapper, .leaflet-popup-tip{
  opacity: 0.8 !important;
  box-shadow: 0 3px 14px rgba(0, 0, 0, 0) !important;
}


/* Custom CSS for Leaflet zoom controls */
.leaflet-control-zoom {
  
}

/* Style for the zoom-in button */
.leaflet-control-zoom-in {
 
}

.leaflet-control-zoom-in:hover {
   /*background: linear-gradient(145deg, #0369A1, #3B82F6); Inverted gradient on hover */
   /* transform: scale(1.05); Slightly increase size on hover */
 /*box-shadow: 0 6px 20px rgba(0, 0, 0, 0.25);  Deeper shadow on hover */
}

/* Style for the zoom-out button */
.leaflet-control-zoom-out {
 
}

.leaflet-control-zoom-out:hover {

}

.leaflet-touch .leaflet-bar a {

}
.custom-popup {
  position: relative;
  /* Default styling */
}

.popup-above .leaflet-popup-tip-container {
  top: 100%; /* The arrow should be at the bottom of the popup when above the marker */
}

.popup-below .leaflet-popup-tip-container {
  top: -10px; /* Adjust this to ensure the arrow appears at the top of the popup when below the marker */
}

.leaflet-touch .leaflet-control-layers,
.leaflet-touch .leaflet-bar {
	border: none !important;
	background-clip: padding-box;
  DISPLAY: FLEX;
  flex-direction: column;
  gap: 4px;
	}

  
.leaflet-touch .leaflet-bar a:first-child {
    border-radius: 9px !important;
    border: 2px solid rgba(0, 0, 0, 0.2) !important;
    }
.leaflet-touch .leaflet-bar a:last-child {
      border-radius: 9px !important;
      border: 2px solid rgba(0, 0, 0, 0.2) !important;
      }

.rtl {
        direction: rtl;
        text-align: right;
      }
      
.ltr {
        direction: ltr;
        text-align: left;
      }

      
.custom-popup .leaflet-popup-tip {
  transform: translateY(-100%); 
  bottom: auto !important; 
  top: 0; 
}

.custom-popup.leaflet-popup-bottom .leaflet-popup-tip {
  transform: translateY(-100%);
  bottom: auto !important;
  top: 0 !important;
}
.popup-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.popup-loading::after {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-left: 8px;
  border-radius: 50%;
  background-color: #333;
  animation: pulse 0.6s infinite alternate;
}

@keyframes pulse {
  from {
    transform: scale(1);
    opacity: 1;
  }
  to {
    transform: scale(1.5);
    opacity: 0.5;
  }
}
