.boxborder{
    margin-left: 2.5rem;
    margin-right: 2.5rem;
    border: 0.1px solid hsl(0, 11%, 95%);
    @apply sm:shadow-lg mx-20 my-3 rounded-md
}

.container{
    padding: 1% 4%;
}
.headerNewOpp{
    color: #3388AA;
}
.lineProp {
    width: 28%;
    height: 1.25px;
    background-color: #EAEAEA;
    margin-bottom: 3%;
}
.gridContainer{
    display: grid;
}
@media (min-width: 701px)  {
    .gridContainer{
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        gap: 0.5rem;
    }
    .gridContainer2{
        display: grid;
        grid-template-columns: repeat(1, 1fr); 
        gap: 0.5rem;
    }
    .gridContainer3{
        display: grid;
        grid-template-columns: repeat(3, 1fr); 
        gap: 0.5rem;
    }

    .gridContainerSelect{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
    }
    .btnWith{
        width: 16.666667%; 
    }
   
    .boxborder{
        border: 0.1px solid hsl(0, 11%, 95%);
        @apply sm:shadow-lg mx-20 my-3 rounded-md
    }
}

.inquiryContentContainer{
    display: flex;
    flex-direction: column;
    gap: 25px;
  
    padding: 20px 70px 40px;
}
.pageContentContainer {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}

.btnProp{
    border-radius: 7px;
    border: 0 ;
    background:  #16A34A;;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: fit-content;
    transition: all 0.5s ease;
    margin: 0 auto;
    @apply px-[4rem] py-3
}
.btnProp:hover{
    cursor: pointer;
    transform:scale(1.02);
    background:#16A34A;

}



.modalMessage{
    color:  #9CA3AF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 400px;
    margin: 0px auto;
}
.modalTitle{
    color:  #374151;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
.exlamationIcon{
    color: orange;
    font-size: xx-large;
}
.checkCrcleIcon{
    color: #16A34A;
    font-size: xx-large;
}

