.table{
    @apply w-full divide-y divide-gray-200;
    border-collapse: collapse;
    background: #FFF;

}
.pageButton{
    @apply relative inline-flex items-center px-1 border rounded-lg;
    border: 1px solid #868FA0;
    background-color: #F7F9FC;
}