.pageTitle {
    color: #0369A1;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.pageTitleMobile {
    display: flex;
    width: 70%;
    padding: 5px 0;
    gap:10px;
    align-items: center;
    justify-content: center;
    color: #374151;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
}
.pageTitleMobileItem {
    text-align: center;
    text-decoration: none;
    margin: 0 ;
    padding: 1px 0;
    display: inline;
    line-height: 1;
    vertical-align: middle;
}

.pageTitleDescription {
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    padding: 25px 0;
}

.pageTitleContainer {
    padding: 50px 50px 0;
}

.pageTitleContainerMobile {
    padding: 20px 30px 0;
}
.pageContentContainer {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}
@media(min-width: 801px){
    .pageContentContainer {
        position: relative;
        overflow: hidden;
        padding: 20px 70px 40px;
        min-height: 100vh;
    }
}
@media (max-width: 800px) {
    .pageContentContainer {
        padding: 0 20px 100px;
    }
}
.searchContainer{
    display: flex;
    width: 100%;
    gap: 10px;
    @apply py-3;
}
.searchItemContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.filterContainer{
    display: flex;
    align-items: center;
}
.filterItemsContainer{
    display: flex ;
    max-height: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @apply  gap-2;
}

.filterItemsContainer>div:first-child{
    flex:2;
}
.filterItemsContainer>div:nth-child(2){
    flex:2;
}
.filterItemsContainer>div:last-child{
    flex:3;
}
.filterItemsContainerAppear{
    max-height: 500px !important;
    @apply pb-6 pt-2;
}
@media (max-width: 800px) {
    .filterItemsContainer{
        flex-direction: column;
    }
}
.notShow {
    visibility: hidden;
    opacity: 0;
}
.inquiryContentContainer{
        display: flex;
        flex-direction: column;
        gap: 25px;
}
.paginationContainer{
    padding: 30px 0;
    display: flex;
    justify-content: center;
}