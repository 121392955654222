/******************single article css******************************/

.header-single-article-menu{
color: var(--Primary-600, #0284C7);

font-size: 30px;
font-weight: 600;
}
.header-single-article{
    display: flex;
    gap: 1px;
    align-items: center;
  justify-content: center;
  padding-top: 2%;
}
.page-read-move-back{
    display: none;
    margin-top: 2%;
    
}
.arrow-right {
    float: right;
  }
@media (max-width: 767px) {
    .header-single-article {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px; 
      }
      
      .header-single-article.ar{
        flex-direction: row-reverse;
        margin-left: 11%;
      }
      .header-single-article.ar .page-read-move-back{
        flex-direction: row-reverse;
        margin-right: 5%;
      } 
      .header-single-article.fr .page-read-move-back{
        margin-left: 30px;
      }

      .header-single-article-menu {
        flex-grow: 1; 
        text-align: center;
        color: var(--Gray-700, #374151);
        font-size: 24px;
        font-weight: 700;
      }
      
      .link-style {
        text-decoration: none;
        color: black; 
      }
    .page-read-move-back{
        display:block
    }
  
    .header-single-article-img {
        display:none
    }
  
    
  }


/******************single article css******************************/

.ql-editor  h1, .ql-editor h2,.ql-editor h3, .ql-editor h4, .ql-editor h5, .ql-editor h6{
    font-family: 'Nunito Sans', sans-serif;
    font-size: 10px;
}
.contenArticle.ql-editor{
    height: auto;
}
.card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
    font-family: 'Nunito Sans', sans-serif;
  }
  
.card.fr {
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
}

.card.fr .content-card {
    display: flex;
    width: 100%;
    height: 192px;
    position: relative;
}

.card.fr .card-media {
    width: 20%;
    height: 100%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
}

.card.fr .card-content {
    width: auto;
    height: auto;
    padding: 9px;
}

.card.fr .card-content h2 {
    font-size: 1rem;
    margin-bottom: 6px;
    white-space: nowrap;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card.fr .card-content p {
    margin: 0;
    font-size: 14px;
    width: auto;
}

.card.fr .card-icons {
    position: absolute;
    bottom: 8%;
    right: 1%;

   
}
.card.fr .card-tag{
    position: absolute;
    bottom: 8%;
}

.card.fr .card-icons span {
    margin-left: 10px;
}

.card.fr .card-icons span:first-child {
    margin-left: 0;
}

.card.fr .card-icons span svg {
    margin-right: 5px;
}

.card.fr .card-content h2:hover {
    overflow: visible;
}

.card.fr .card-icons i {
    color: rgb(8, 88, 145);
}

.card.fr .card-content-article {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid rgb(8, 88, 145);
    border-radius: 10px;
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.card.fr .read-more {
    color: rgb(8, 88, 145);
}

.card.ar {
    /* border: 1px solid rgb(8, 88, 145); */
    width: 100%;
    height: auto;
    position: relative;
    border-radius: 10px;
    /* box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; */
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    /* border: 1px solid rgb(8, 88, 145); */
   
}

.card.ar .content-card {
    display: flex;
    padding: 10px;
    width: 100%;
    height: 192px;
    position: relative;
    margin-bottom: 3%;
    text-align: right; 
    direction: rtl;
}

.card.ar .card-media {
    width: 20%;
    height: 100%;
    margin-left: 20px;
    margin-top: -35px;
}

.card.ar .card-content {
    width: auto;
    height: auto;
}

.card.ar .card-content h2 {
    font-size: 1rem;
    margin-bottom: 6px;
    white-space: nowrap;
    width: 75%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card.ar .card-content p {
    margin: 0;
    font-size: 14px;
    width: auto;
}

.card.ar .card-icons {
    position: absolute;
    bottom: -9%;
    left: 1%; 
}

.card.ar .card-icons span {
    margin-right: 10px; 
}

.card.ar .card-icons span:first-child {
    margin-right: 0; 
}

.card.ar .card-icons span svg {
    margin-left: 5px; 
}

.card.ar .card-content h2:hover {
    overflow: visible;
}

.card.ar .card-icons i {
    color: rgb(8, 88, 145);
}

.card.ar .card-content-article {
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 5px;
    padding: 10px;
    border: 1px solid rgb(8, 88, 145);
    border-radius: 10px;
    width: 100%;
    height: auto;
    position: relative;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}


.card.ar .read-more {
    color: rgb(8, 88, 145);
}

.card .content{
        padding: 12px;

}

.card.no-border {
    border: none;
    box-shadow:none
}

.card.with-border {
    /* border: 1px solid black; */
}
@media screen and ( max-width : 600px ) {
    .articleContainer {
        align-items: center;
        gap: 11px;
    }

    .main-current-menu{
        display: none;
    }
      .card.fr .content-card {
        display: grid;
        grid-template-rows: max-content 260px 1fr;
        height: auto;
      }
      .card.fr .card-tag {
        position: relative;
        bottom: 0;
      
        
    }
      .card.fr .card-icons {
        position: relative;
        bottom: 0;
        right: 0;
      }
      
      .card.fr .card-media {
        object-fit: cover;
        width: 100%;
        height: 100%;
        margin-right: 0;
        border-bottom-left-radius: 0;
    border-top-right-radius: 10px;
      }
      .card.fr .card-content p {
        font-size: 14px;
      }
      form .form-infos {
        FLEX-WRAP: wrap;
    }
.ql-editor img{
    WIDTH: 100%;
    OBJECT-FIT: contain;
}
.card.fr {
    width: 90VW;
    margin-top: 0;
     padding: 0;
       margin-bottom: 0;
}
.card.fr .card-content {
    padding: 11px;
}

.card.fr .card-content h2 {
    text-align: center;
}
.card.fr .content-card {
    padding: 0;
}
form .form-infos {
    
      gap: 0 !important;
        
    
}
.form-group {
    margin-bottom: 0% !important;
}
.commentInput {
    margin-bottom: 0rem !important;

  
}
.comment-input-article{
    width: 74% !important;
}
}

@media screen and ( min-width : 2000px ) {
.seacrh-bar {
    width:100%;
}
}