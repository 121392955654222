
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(6px);
    align-items: center;
    display: flex;
    justify-content: center;
    z-index: 1000;
    transition: all 0.5s linear;
}

.modal {
    background: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    width: fit-content;
    max-width: 70%;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.show-modal {
    animation: showModal .2s forwards
}
.hide-modal{
    animation: hideModal .2s forwards
}
.close-modal{
    visibility: hidden;
    opacity: 0;
}

@keyframes showModal {
    from {
        opacity: 0;
        transform: scale(0.7);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}
@keyframes hideModal {
    from {
        opacity: 1;
        transform: scale(1);
    }
    to {
        opacity: 0;
        transform: scale(0.7);
    }
}


