@import "https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&display=swap";

.errorTitle {
    color: rgb(2, 132, 199);
    font-size: 40px;
    text-align: center;
    font-weight: 600;
}


.errorDescription{
    color:#3E2723;
    font-size: 16px;
    font-style: normal;
    text-align: center;
    font-weight: bold;
    line-height: 150%;
}
.errorContainer{
   @apply flex flex-col justify-center items-center  w-10/12 mx-auto rounded-lg px-6 py-6;
}
.container{
    min-height: 87vh;
}

@media (min-width: 801px) {

    .logo{
        width: 60%;
    }
    .errorTitle {
        font-size: 40px;
    }
    .errorDescription{
        font-size: 18px;
    }
}

@media (max-width: 800px) {
 .container{
     @apply flex flex-col justify-center items-center;
 }
    .logo{
        width: 100%;
    }

    .errorTitle {
        font-size: 20px;
    }
    .errorDescription{
        font-size: 10px;
    }
}