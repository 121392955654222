.logoContainer{
    display: flex;
    justify-content: space-between;
    @apply py-2
}
.pageContentContainer{
    @apply flex flex-col justify-between;
    width: inherit;
    min-height: 99vh;
}


.pageTitle{
    color:  #374151;
    font-size: 20px;
    font-weight: 800;
    line-height: 150%;
    @apply w-fit
}
.pageTitleDescription{
    color: #6B7280;
    font-size: 16px;
    line-height: 150%;
    @apply w-fit
}
.singIn{
    color:  #374151;
    font-size: 15px;
    font-weight: 400;
    @apply flex justify-center gap-2 py-2

}
.signLink{
    color:  #0369A1;
    font-size: 14px;
    font-weight: 600;
    cursor:pointer;
}
.skipButton{
    color:  #0369A1;
    font-size: 15px;
    border-bottom: 1px solid #0369A1;
    font-weight: 500;
    cursor: pointer;

}
.skipButton:hover{
    font-weight: 600;
}
.legalFooter{
    color:  #ADABC3;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    @apply w-full flex flex-col gap-2 items-center;
}
.legalFooterElements{
    @apply cursor-pointer hover:text-slate-300 transition-all duration-300;
}
.progressText{
    color: #404D61;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    @apply flex justify-center;
}
.pageSignUpMessage{
    color: #4B5563;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    @apply text-center
}
.sliderStyle{
    flex: 0 0 100% ;
    position: relative ;
    display: block;
    transition: transform 0.5s ease-in-out;
    @apply px-6;
}
.carouselText{
    color: #FFF;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    @apply text-center
}
.carouselDescription{
    color: #FFF;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    @apply text-center
}
.stepProcessTitle{
    color: #374151;
    font-size: 16px;
    font-weight: 700;
    @apply flex gap-6 py-4 cursor-pointer w-fit;
}
.formItemsContainer{
    max-height: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    display: grid;
}
@media (min-width: 801px) {
    .formItemsContainer{
        grid-template-columns: 3fr 3fr auto;
    @apply items-center gap-5
    }
}
@media (max-width: 800px) {
    .formItemsContainer{
      @apply grid-rows-2 items-center;
    }
}
.stepProcessListTitle{
    font-size: 17px;
    font-weight: 700;
    @apply m-2 text-primary  w-fit;
}
.formItemsContainerAppear{
    max-height: 500px !important;
    @apply pt-4 pb-1
}
.procedureEventAppear{
    max-height: 500px !important;
    @apply transition-all duration-700
}
@media (max-width: 800px) {
    .formItemsContainer{
        flex-direction: column;
    }
}
.stepProcessItemContainer{
    @apply h-[250px] overflow-y-auto py-4 px-2 flex flex-col gap-5;
}
.stepProcessContainer{
    border: 2px solid rgba(240, 240, 240, 0.50);
    @apply py-1 px-6 bg-slate-50 rounded-lg;
}
.radioFieldStepD{
    color: #0369A1 !important;
    font-size: 12px;
}
.redMessage{
    color:  #F87171;
    font-size: 12px;
    font-weight: 400;
    height: 15px;
    @apply py-1.5;
}
.stayConnectLabel{
    color:  #6B7280;
    font-size: 12px;
    font-weight: 400;
}
.profileChooserContainer{
    @apply grid gap-3;
}
.carouselContainer{
    width: 30%;
    @apply bg-primary fixed h-svh
}
.formContainerStepH{
    @apply grid grid-rows-3 py-2;
}
.formContainerStepE{
    @apply grid grid-rows-2 gap-3 py-4 ;
}
.formContainerStepD{
    @apply flex items-center pt-2 pb-3
}

@media (max-width: 800px) {
    .gridDispaly{
        @apply  grid grid-cols-1
    }
    .profileChooserContainer{
        @apply py-2;
    }
    .formContainerStepE{
        @apply px-4;
    }

    .formContainerStepD{
        @apply  flex-col gap-6;
    }
    .formTwoContainerStepD{
        @apply grid grid-rows-2
    }
}
@media (min-width: 801px) {
    .profileChooserContainer{
        @apply py-2;
    }
    .pageContentContainer{
        width: 66%
    }
    .pageContentContainerFr{
        margin-left: 30%;
    }
    .pageContentContainerAr{
        margin-right: 30%;
    }
    .gridDispaly{
        @apply  grid grid-cols-2
    }
    .formContainerStepE{
        @apply px-12 ;
    }
    .formContainerStepH{
        @apply px-16;
    }
    .formContainerStepD{
        @apply  gap-3;
    }
    .formTwoContainerStepD{
        @apply transition-all duration-1000 grid grid-cols-2 gap-x-2;
    }
}
.confirmTitle{
font-size: 24px;
font-weight: 400;
color: #059669;
}
.confirmMessage{
font-size: 18px;
font-weight: 400;
color: #374151;
}
.confirmPageContent{
 min-height: 90vh;
}
.forgetPassInputLabel{
    color: #6B7280;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
}
.minHight{
    min-height: 65%;
}
.selectedProfile{
    color:  #0369A1;
    font-size: 20px;
    font-weight: 700;
    @apply flex items-center gap-3
}

