.cardContainer{
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid  #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    @apply gap-8 py-4 px-6
}
@media (max-width: 800px) {
    .activityCardContainer{
        flex-direction: column;
        padding: 20px 15px;
        gap: 20px !important;
    }
}
.activityCardSkeletonContainer{
    display: flex;
    gap: 15px;
    border-radius: 8px;
    border: 1px solid  #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    padding: 10px 60px 15px 15px;
    justify-content: space-between;
}
@media (max-width: 800px) {
    .activityCardSkeletonContainer{
        flex-direction: column;
        padding: 20px 15px;
        gap: 20px !important;
    }
}
.cardTitle{
    @apply flex gap-4;
    color:  #0369A1;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
@media (max-width: 801px) {
    .cardTitle{
        color: #374151 !important;
    }
}
.cardDescriptionContainer{
    display: flex;
    flex-direction: column;
    color: #374151;
    font-weight: 500;
    font-size: 15px;
    @apply gap-6;
}
.cardDescriptionItemContainer{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    @apply gap-x-6
}
.cardDescriptionContainer>span{
    display: flex;
    @apply gap-2;
}
.cardDescriptionItem{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.cardDescriptionItem > span {
    display: flex;
    @apply gap-2;
}
.activityContentContainer{
    display: flex;
    flex-direction: column;
    gap: 25px;
}
.cardText{
    display: flex;
    flex-direction: column;
    @apply gap-6 w-full
}
.cardAddress {
    display: flex;
    font-size: 15px;
    color: #374151;
    font-weight: 500;
    @apply gap-3
}
@media (min-width: 801px) {
    .cardText{
    }
}
.cardButtonsContainer{
    display: flex;
    flex-direction: column;
    justify-content: center;
    @apply gap-2;
}
.cardButtons:hover{
    background: #ffffff !important;
    color: #0369A1 !important;
}
.cardContentContainer{
    @apply flex justify-between;
}
.cardImageContainer{
    background: #FFF;
    box-shadow: 3px 5px 8px 2px rgba(158, 157, 156, 0.12);
    height: 200px;
    @apply p-1 rounded-sm hover:cursor-pointer;
}
.inquiryCardSkeletonContainer{
    display: flex;
    align-items: center;
    border-radius: 8px;
    border: 1px solid  #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    @apply gap-8 px-6 py-3; ;
}
.inquirySkeletonImageContainer{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    height: 200px;
    width: 40%;
}

.cardButtonsContainerMobile{
    display: flex;
    justify-content: center;
    @apply gap-2;
}
.cardContentContainerMobile{
    @apply flex justify-between flex-col gap-3;
}
.cardContentContainerMobile > span{
    color:  #374151;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    @apply flex gap-2;
}
.cardTitleContainerMobile{
    @apply flex justify-between items-start;
}
.cardContainerMobile{
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    border: 1px solid  #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    @apply gap-8 py-4 px-6
}
.cardTitleMobile{
    display: flex;
    flex-direction: column;
    @apply gap-2;
}
.cardTitleMobile > span:first-child{
    color:  #374151;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 145%;
}
.cardTitleMobile > span:last-child{
    color : #374151;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}
.whiteButtonMobile{
    font-size: 12px;
    font-weight: 500;
}
.whiteButtonMobile:hover{
    background: #0369A1 !important;
    color: #FFFFFF !important;
}
.blueButtonMobile{
    font-size: 12px;
    font-weight: 500;
}
.blueButtonMobile:hover{
    background: #FFFFFF !important;
    color: #0369A1 !important;
}
.inquiryCardSkeletonMobileContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 8px;
    border: 1px solid  #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
    @apply  px-6 py-3;
}