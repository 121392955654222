.inputContainer{
    gap: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    width: inherit;
    min-width: 250px;
    border: 1px solid #6B7280;
    background:white;
    @apply px-3 py-1.5
}
.inputItem{
        border: none;
        align-items: center;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 90%;
        outline: none;
        font-size: 16px;
        font-weight: 500;
        line-height: 150%;
        background:white;
        color: #212121
}

.inputFormItemContainer{
    border-radius: 4px;
    border: 0.5px solid var(--Gray-300, #D1D5DB);
    background: #FFF;
    transition: all 0.5s ease;
    @apply w-auto px-3 py-2 flex justify-between items-center
}
.inputPhoneFormItemContainer{
    border-radius: 4px;
    border: 0.5px solid var(--Gray-300, #D1D5DB);
    background: #FFF;
    transition: all 0.5s ease;
    @apply w-auto px-3  flex justify-between items-center pt-4 pb-1.5
}
.inputFormItemContainer:focus{
    border: 1px solid #0369A1;
}
.inputFormItem{
@apply w-full pb-1 pt-3.5 outline-none border-none;
    box-sizing: border-box;
    color: #374151;
    font-size: 16px;
    font-weight: 400;
}
.inputFormItemWithLabel{
    @apply pb-1 pt-3.5;
}
.inputFormItemWithoutLabel{
    @apply pb-2
}
.inputPhoneFormItem {
    @apply w-full outline-none border-none;
    box-sizing: border-box;
    color: #374151;
    font-size: 16px;
    font-weight: 400;
}

.inputSuffixItem{
    @apply pb-1 pt-3.5 px-9 outline-none border-none;
    box-sizing: border-box;
    color: #374151;
    font-size: 17px;
    font-weight: 400;
}
.inputFormItem::placeholder {
    color: #616161;
    font-size: 14px;
    font-weight: 300;
}
.inputFormLabel{
    position: relative;
    top: 10px;
    @apply px-1 mx-3 z-10 w-fit bg-white;
    color:rgba(34, 51, 84, 0.50);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    transition: transform 0.1s ease;
}

.inputFormContainer{
    position:relative;
}
.inputFormContainer:has(.inputFormItem:focus) .inputFormLabel{
    position: relative;
    top: 6px;
    @apply px-1 mx-3 z-10 w-fit bg-white;
    color: #0369A1 !important;
    transform: scale(1.08);
    font-weight: 500 !important;
}
.inputFormContainer:has(.inputFormItem:focus) .inputFormItemContainer{
    border: 0.5px solid #0369A1 ;
}
.errorMessage{
    color:  #F87171;
    font-size: 12px;
    font-weight: 400;
    transition: all 0.3s linear;
    height: 15px;
    @apply py-2;
}
.notEmptyErrorMessage{
    opacity: 1;
    visibility: visible;
}
.emptyErrorMessage{
    opacity: 0;
    visibility: hidden;
}
.searchMenu{
    position: absolute;
    width: 100%;
    max-height: 250px;
    overflow-y: scroll;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #0369A1;
    border-top:none;
    border-radius: 0 0 4px 4px;
    background: #fff;
    z-index: 20;
    transition: all 0.3s ease-in;
    @apply py-2
}
.searchMenuItem{
    padding: 15px;
    cursor: pointer;
    transition: background-color 0.3s;
    font-size: 16px;
}
.searchMenuItem:hover{
    background: #F9FAFB;
}
