@import "https://fonts.googleapis.com/css2?family=Tajawal&family=Inter&family=Rubik&display=swap";

.rubik{
    font-family: 'Rubik',sans-serif;
}
.semi-bold-fr {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}
.regular-fr {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}
.medium-fr {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}
.bold-fr{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
.extra-bold-ar {
    font-family: 'Tajawal', sans-serif;
    font-weight: 600;
}
.regular-ar {
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}
.medium-ar {
    font-family: 'Tajawal', sans-serif;
    font-weight: 500;
}
.bold-ar {
    font-family: 'Tajawal', sans-serif;
    font-weight: 700;
}

.global-btn{
    font-size: 15px;
    width : 100%;
    border-radius: 4px;
    box-shadow: 0 0 1px 0 #ccc;
    border: 1px solid transparent;
    padding: 7px 16px;
    cursor: pointer;
    -webkit-user-select: none; /* Safari */        
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
    transition: 0.2s background-color;
    text-align: center;
}

@media screen and ( max-width : 900px ) {
    .global-btn{
        padding: 7px 10px;
    }

    .blue-button {
        padding: 5px 10px ;
    }
}

.global-btn-container{
    display: flex;
    flex: 1;
    width: -moz-fit-content;
    min-width: fit-content;
}

.blue-button:focus{
    box-shadow: 0 1px 7px 1px #ccc;
    background-color: rgb(8, 88, 145)!important;
    transition: 0.3s background-color;
}
.white-button:focus{
    background-color: #F9FAFB!important;
    transition: 0.3s background-color;
    box-shadow: 0 0 4px 0px #ccc;
}

.blue-button:hover{
    background-color: rgb(8, 88, 145)!important;
    color: #fff !important;
    transition: 0.3s background-color;
}

.white-button:hover{
    background-color:#F9FAFB!important;
    transition: 0.3s background-color;
}

.articleContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    align-content: center;
    /* margin: 9% 11%; */
    
    /* height: 100vh; 
    overflow-y: auto; */
    margin-top: 5%;
  }
  .articleContainer .ListArticles{
    display: flex;
    align-items: center;
    flex-direction: column;
    
  }
  .articleContainer.without-margin{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    margin: 4%;

  }

  .enquete{
    display: flex;
    flex-direction: column;
    margin:3% 10%;
  }
  .enqueteDescription {
    color: #373B3D;
    text-align: justify;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150.5%;
    margin-bottom: 2%;
    margin-top: 2%;
    width: 100%;
    margin-left: 10px;
  }
  .menuEnquete{
    color: #373B3D;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  @media only screen and (min-width: 1500px){
    .enquete{
        margin: 3% 15%;
    }
  }


  




  















/* ***************************************************HEADER ************************************************ */
.header{
    background-color: #fff;
    box-shadow: 0px 2px 2px 0px rgba(240, 240, 240, 0.50);
    display: flex;
    justify-content: space-between;
    height: 12vh;
    min-height: 70px;
    padding: 0 20px;
    top: 0;
    position: sticky;
    z-index: 900;
}
@media (max-width:1000px){
   .header{
       padding: 0 10px;
   }
}

.header-user>div>.global-btn-container>button{
    box-shadow: none;
}
.header-user>.global-btn-container>button{
    width: 160px;
}
.header-user .global-btn-container:last-child{
    margin-left: auto;
}
.header-user{
    display: flex;
    align-items: center;
}
.header-menu{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 6px;
    font-size: 17px;
    @apply gap-10
}
#menu-burger{
    display: flex;
    gap: 6px;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    width: 30px;
}
#menu-burger>span:nth-child(1),#menu-burger>span:nth-child(2),#menu-burger>span:nth-child(3){
    width: 30px;
    border-top: 3px solid #0369A1;
    transform: rotate(0deg) translateX(0px) scale(1);
    transition: 0.5s transform;
}
#menu-burger>span:nth-child(2){
    width: 20px;
}
.menu-burger-clicked>span:nth-child(2){
    scale: 0!important;
}
.menu-burger-clicked>span:nth-child(1){
    transform: rotate(405deg) translateX(12px)!important;
    width: 25px!important;
    margin-right: 5px;
}
.menu-burger-clicked>span:nth-child(3){
    transform: rotate(-405deg) translateX(13px)!important;
    width: 25px!important;
    margin-right: 5px;
}
.header-container-logo>a{
    height: fit-content;
}
.header-container-logo img{
    height: 35px;
    vertical-align: middle;
}
.header-container-logo{
    display: flex;
    justify-content: center;
    align-items: center;
}
@media (max-width:1200px){
    .header-container-logo{
        gap: 15px;
    }
}

.ant-menu-item>.to-unset-font{
    font-size: 18px;
    text-align: center;
}

.menu-to-show-hover{
    scale: 1 !important;
    visibility: visible !important;
    opacity: 1 !important;
}

.header-menu-items{
    font-weight: 400;
    color: #075985;
}

.header-menu-to-hover>div>.to-unset-font{
    position: relative;
    top: 2px;
    left: 2px;
    transform: rotateZ(0);
    transition: 0.3s all;
}
.header-menu-to-hover:hover>div>.to-unset-font{
    transform: rotateZ(180deg);
}

.header-menu-to-show{
    display: flex;
    transform-origin:  top;
    position: absolute;
    background-color: #0369A1;
    box-shadow: 0px 1px 7px -3px #000;
    border-radius: 12px;
    padding: 6px 16px;
    top: 30px;
    transform: translateX(-50%);
    max-width: 100vw;
    width: max-content;
}
.menu-to-show{
    transition: 0.1s all;
    opacity:1 ;
    scale: 0;
    visibility: hidden;
}
.header-menu-to-show>div{
    margin: 0 22px 15px;
}
.header-menu-to-show *{
    color: #fff;
}
.header-menu-to-hover{
    cursor: pointer;
}
.header-menu-column-title{
    width: fit-content;
    margin: 0 0 10px 0;
    padding-bottom: 11px;
    margin-bottom: 12px;
    border-bottom: 1px solid #fff;
}
.header-menu-column>a{
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    text-decoration: none;
    font-size: 14px;
}
.header-menu-column>div{
    display: grid;
    grid-template-columns: 20px auto;
    gap: 10px;
    text-decoration: none;
    font-size: 14px;
}

.header-menu-column{
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.header-menu-to-hover{
    color: #373B3D;
    display: flex;
    align-items: center;
    position: relative;
}

.ant-menu-item .logo-img{
    width: 16px;
}
.header-menu-to-hover>.to-unset-font{
    position: absolute;
    top: 13px;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 1;
    color: #0369A1;
    font-size: 25px;
    transform: rotateX(55deg);
}
.choice-language-container .global-btn{
    box-shadow: none;
}
.sign-menu-container .global-btn{
    box-shadow: none;
}

.choice-language-container:hover .rotate-awsome-icon{
    -webkit-transform: rotate(180deg);
     transform: rotate(180deg);
}


.header-mini-pipe{
    border-right: 2px solid #0369A1;
    width: 20px;
    display: inline-block;
    height: 20px;
}
.header-buttons-account{
    display: flex;
}
.header-lang-mini{
    gap: 6px;
    font-weight: 700;
}
.header-lang-mini>.to-unset-font{
    font-size: 11px;
    height: fit-content;
    display: inline-block;
    margin-top: 2px;
}
.header-lang-mini>img{
    width: 12px;
}
@media screen and ( min-width : 1101px ) {
    .header-medium,.header-mini{
        display: none;
    }
    .header-medium.header-large{
        display: flex;
        gap: 5px;
    }
    .header-medium.header-mini{
        display: none!important;
    }
    .header-mini-container{
        display: none;
    }
}
@media screen and ( max-width : 1100px ) and ( min-width : 701px ) {
    .header-large,.header-mini{
        display: none;
    }
    .header-medium.header-large{
        display: flex;
        gap: 5px;
    }
    .header-medium.header-mini{
        display: flex;
    }
}
@media screen and ( max-width : 700px ) {
    .header-mini{
        display: flex;
    }
    .header-large,.header-medium{
        display: none;
    }
}
.header-mini-container .header-categorie-name{
    margin: 10px 0px;
}
.header-mini-container .menu-categorie-list-items-globalcomponent{
    padding: 0;
}
@media screen and ( max-width : 600px ) {
    .header-user>.header-mini>img{
        width: 20px!important;
    }
    .header-lang-mini{
        font-size: 13px;
    }
}






















/* *************************************************** < FOOOTER > ************************************************ */
.footer-columns-mini{
    display: flex;
    flex-direction: column;
    padding: 30px 25%;
}
.footer-columns{
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}
.footer-column{
    display: flex;
    flex-direction: column;
    gap: 5px;
    overflow: hidden;
    transition: height 1s ease;
}
@media (max-width: 800px) {
    .footer-column {
        margin: 10px 10px;
    }
}
@media (min-width: 801px) {
    .footer-column {
        margin: 20px 10px;
    }
}
.footer-column>div:first-child{
    margin-bottom: 8px;
}
.footer-column>div{
    margin: 0 10px;
    font-weight: 600;
    font-size: 16px;
}
.footer-column>div:last-child>a{
    font-size: 14px;
    margin: 5px 0;
    text-decoration: none;
}
.footer{
    background: #0369A1;
    position: relative;
}
.footer *{
    color: #fff;
}
.footer-column-items{
    display: flex;
    flex-direction: column;
    gap: 5px;
    transition: transform 0.2s ease;
}
.footer-column>div:last-child *{
    text-decoration: none;
    font-size: 14px;
    font-weight: 500;
}

.collapsed-footer-mini {
    transform: scaleY(1);
}
.nonCollapsed-footer-mini{
    transform: scaleY(0);
    max-height: 15px;
}

.footer-column-title-mini{
    display: flex;
    justify-content: space-between;
    cursor: pointer;
}

.footer-top{
    display: flex;
    flex-direction: row;
    position: relative;
    z-index: 1;
}
.footer-top>div:first-child{
    display: flex;
    flex-direction: column;
    max-width: 300px;
    margin: 20px;
    gap: 30px;
}
.footer-top>div:first-child>div:last-child{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.footer-top>div:first-child>img{
    width: 160px;
}
.footer-rights .global-btn{
    background: rgba(238, 238, 238, 0.16);
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.04);
    backdrop-filter: blur(3px);
    border-radius: 10px;
    flex: none;
    order: 0;
    flex-grow: 0;
    padding : 2px 10px ;
}
.footer-button-content>span>span:first-child{
    letter-spacing: 1px;
    font-size: 10px;
}
.btn-footer{
    box-shadow: 0 0 10px 1px #f3ebeb;
    padding: 2px 6px!important;
}
.footer-button-content{
    display: flex;
    align-items: center;
}
.footer-button-content>span{
    display: flex;
    flex-direction: column;
}
.footer-button-content>span>span:last-child{
    font-weight: 700;
}
.footer-button-content>span>span{
    margin: 3px;
}
.footer-button-content>*{
    margin: 0 2px;
}
.footer-rights{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 60px 20px 0;
    position: relative;
    z-index: 1;
}
.footer-rights>div:first-child{
    font-size: 15px;
}
.footer-rights>div:last-child{
    display: flex;
    flex-direction: row;
    gap: 10px;
}
.footer-waves-right{
    position: absolute;
    left: 0;
    bottom: 0;
}
.footer-waves-left{
    position: absolute;
    right: 0;
    bottom: 0;
}


.btns-footer{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    max-width: 400px;
    align-items: center;
    justify-content: flex-end;
}
.btns-footer-mini {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
    justify-content: center;
}
.footer-mini{
    padding: 20px;
}
.footer-mini-logo{
    width: fit-content;
    margin: auto;
    padding: 20px;
}
.footer-mini-logo>img{
    width: 180px;
}
.global-btn-container{
    max-width: 240px;
    transition: all 0.2s ease;
}
@media (max-width: 700px)  {
    .global-btn-container{
        max-width: 100%;
        bottom: 30%;
        position: relative;
        
    } 
}
.global-btn-container-hover:hover{
    transform: scale(1.05);
}
.footer-mini-socialmedia{
    display: flex;
    justify-content: center;
    gap: 5px;
    margin: 20px;
}
.footer-rights-container{
    display: flex;
    justify-content:space-between ;
}
.footer-rights-container>div{
    z-index: 1;
}
.footer-mini .footer-rights-container>div{
    text-align: center;
    font-size: 9px;
    display: flex;
    align-items: center;
    padding: 20px;
}
.footer-mini .footer-column>div:first-child{
    font-size: 14px;
}
.footer-mini .footer-column>div:last-child>a{
    font-size: 12px;
}
.global-btn.btn-footer{
    filter: blur(0.2px) drop-shadow(0px 0px -4px #fff);
    box-shadow: 0px 0px 10px rgb(0 0 0 / 4%);
    background: rgba(238, 238, 238, 0.16);
}
.footer-mini .footer-columns{
    justify-content: space-evenly;
}
.footer-rights-container>div:first-child{
    font-size: 11px;
    margin: 10px;
}

.footer-mini-layout{
    display: none;
}

.footer-mini-layout>{

}
.footer-large-layout>{

}

@media screen and ( max-width : 1000px ) {
    .footer-large-layout{
        display: none;
    }
    .footer-mini-layout{
        display: unset;
    }
    .footer-rights-container {
        justify-content: center;
    }
}
@media screen and ( max-width : 800px ) {
    .footer-columns>div:nth-child(4) {
        display: none;
    }
}
@media screen and ( max-width : 600px ) {
    .footer-columns>div:nth-child(1) {
        display: none;
    }
    .footer-rights-container>div:first-child{
        font-size: 8px;
    }
}

/* *************************************************** < FOOOTER /> ************************************************ */

























/******************* topcomponent-items ******************/
.topcomponent-items>div{
width: fit-content;
}

.topcomponent-items{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    justify-content: center;
}
.topcomponent-items-extra{
    gap: 1px;
    background: #D4E3EB;
}


.topcomponent-item{
    display: grid;
    margin-bottom: 30px;
    justify-content: center;
    grid-template-columns: repeat(3, 1fr);
    background: white;
    height: fit-content;
    gap: 40px;
}
.topflexcomponent-item{
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-grow: 1;
    gap: 20px;
    width: 30%;
    background: white;
}

.topcomponent-items>div{
    padding: 5px 25px;
}

.global-component-link>a{
    color: #0369A1;
    text-decoration: none;
}

.global-component-link>a>.to-unset-font{
    position: relative;
    margin-left: 4px;
    left: 0px;
    top: 2px;
    transition: 0.3s left;
    font-size: 16px!important;
}
.global-component-link>a:hover>.to-unset-font{
    left: 8px;
    transition: 0.5s left;
}

.topcomponent-item-description{
    color : #374151 ;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    height: 30%;
    line-height: 130%;
}
.title{
    margin: 1% auto 5%;
    text-align: center;
    font-size: 26px;
    font-weight: 600;
    color: #374151;
}
.paragraph{
    max-width: 900px;
    margin: 10px auto;
    text-align: center;
    font-size: 19px;
    color: #374151;
}
.title-paragraph{
    margin: 0px auto 3% auto;
    width: fit-content;
    padding: 30px 15px 0 15px;
}

.global-component-button-radio{
    display: grid;
    grid-template-columns: 40px auto;
    gap: 10px;
}
.global-component-button-radio-label{
    color: #0284C7;
}
.global-component-button-radio-point{
    box-shadow: 0 0 8px -2px #ccc;
    height: 20px;
    width: 40px;
    border-radius: 20px;
    cursor: pointer;
    position: relative;
    transition: 0.5s background-color;
}
.global-component-button-radio-point>span{
    display: inline-block;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background-color: #fff;
    position: absolute;
    left: 0;
    transition: 0.5s left;
}



/* mini Menu */
.choice-language-container{
    position: relative;
}
.choice-language-container .global-btn{
    font-weight: 600;
}

.choice-language-list-container{
    position: absolute;
    padding: 8px 15px;
    box-shadow: 0 0 10px -5px #827575;
    border-radius: 10px;
    background: #fff;
    min-width: 150px;
    display: none;
    color: #0369A1;
}
.sign-menu-container{
    position: relative;
}
.sign-menu-list-container{
    position: absolute;
    padding: 8px 15px;
    box-shadow: 0 0 10px -5px #827575;
    border-radius: 10px;
    background: #fff;
    min-width: 150px;
    display: none;
    color: #0369A1;
}

.choice-language-list-container-left{
    right: 9%;
}
.choice-language-list-container-right{
    left: 9%;
}
.sign-menu-list-container-right{
    left: 9%;
}
.sign-menu-list-container-left{
    right: 9%;
}

.choice-language-container:hover>.choice-language-list-container{
    display: block;
}
.sign-menu-container:hover>.sign-menu-list-container{
    display: block;
}

.choice-language-container .sign-menu-container .global-btn>span>.to-unset-font{
    transform: rotateZ(0);
    transition: 0.3s all;
}
.choice-language-container:hover .global-btn>span>.to-unset-font,.sign-menu-container:hover .global-btn>span>.to-unset-font{
    transform: rotateZ(180deg);
}
.choice-language-list-container>ul,.sign-menu-list-container>ul{
    list-style-type: none;
    padding: 0;
    margin: 0;
}
.choice-language-list-container>ul>li:not(:last-child),.sign-menu-list-container>ul>li:not(:last-child){
    border-bottom: 1px solid #ccc;
}
.choice-language-list-container>ul>li,.sign-menu-list-container>ul>li{
    padding: 8px 10px;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
}

.choice-language-list-container>ul>li:hover,.sign-menu-list-container>ul>li:hover{
    color: #6ab5de;
}
.header-mini-menu-top{
    display: grid;
    background-color: #0369A1;
    height: 50px;
    justify-content: space-evenly;
    align-items: center;
    grid-template-columns: 1fr 1fr 1fr 1fr;
}
.header-mini-menu{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 6px;
    cursor: pointer;
    height: 100%;
}
.header-mini-menu-enabled{
    background-color: #0284C7;
    min-width: 130px;
    gap: 10px;
}
.header-mini-menu>img{
    width: 23px;
}
.header-mini-menu>span{
    color: #FFFFFF;
}
.header-mini-menu-body{
    margin: 10px;
}

.header-categorie-name{
    display: flex;
    justify-content: space-between;
    color: #0369A1;
    cursor: pointer;
    text-transform: uppercase;
    margin: 15px 0 22px 0;
    font-size: 14px;
}
.header-categorie-name>span{
    padding: 8px 0;
    border-bottom: 1px solid #fff;
}
.header-categorie-name>img{
    transition: 0.7s transform;
    transform: rotateZ( 0 );
}
.header-categorie-menu-rotate{
    transform: rotateZ( 180deg )!important;
}
.header-mini-item-list-item{
    display: flex;
    justify-content: flex-start;
    gap: 15px;
    color: #4B5563;
    margin: 10px;
}
.header-mini-item-list{
    margin: 15px 0;
}



.menu-categorie-items-globalcomponent{
    display: flex;
    flex-direction: column;
    gap: 8px;
}




.header-mini-menu>span{
    color: #FFFFFF;
    text-transform: capitalize;
    font-size: 15px;
    font-weight: 500;
}



.header-item{
    margin: 15px;
}
.header-item-categorie-list{
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.header-item-categorie-list>a{
    text-decoration: none;
    display: flex;
    gap: 10px;
    align-items: center;
}

.header-mini-menu>.to-unset-font{
    font-size: 22px;
    font-weight: 300;
    height: fit-content;
    display: flex;
}

/********************************/


.header-item-list-item>img{
    height: 20px;
    max-width: 20px;
}

.header-mini-container{
    border-radius: 0 0 50px 0;
    position: fixed;
    z-index: 100;
    width: 100%;
    background: #fff;
}
.header-mini-container .header-item-list-item{
    color: #4B5563;
} 

.menu-categorie-globalcomponent{
    margin: 10px;
}
.header-mini-menu-bottom{
    padding: 10px 10px 25px ;
}
.header-mini-menu-bottom-list{
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    padding: 0 4%;
}
.header-mini-menu-bottom-title{
    color: #4B5563;
    font-weight: 700;
    font-size: 20px;
}
.header-mini-menu-bottom{
    display: flex;
    flex-direction: column;
    gap: 20px;
}
.header-mini-menu-bottom *{
    color: #4B5563;
}
.header-mini-menu-bottom-list{
    display: flex;
    flex-direction: column;
    align-items: center;
}
@media screen and ( max-width : 500px ) {
    .header-mini-menu-bottom-list{
        grid-template-columns: auto;
    }
}

body {
    margin: 0 !important;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}
body[lang="fr"]{
    font-family: 'Inter',sans-serif;
}
body[lang="ar"]{
    font-family: 'Tajawal',sans-serif ;
}
.body-content{
    background-color: #fff;
    margin: 30px 0px 0px;
}
@media (max-width: 1000px) {
    .body-content
    {
        margin: 10px 0px 0px;
    }
}

* {
    scroll-margin-top: 12vh
}
.cancel-actived{
    height: 100vh;
    position: fixed;
    width: 100%;
    background: transparent;
    z-index: 1;
}

.body-content-cancel{
    filter: brightness(0.4)!important;
}



span.to-unset-font.landing-page-functionality-img{
    width: 120%;
    height: 100%;
}

.message-in-modification{
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX( -50% );
    background: #fff;
    color: #0369A1;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 15px 50px;
    font-size: 16px;
    z-index: 100;
    box-shadow: 0 8px 10px 0 #ccc;
    opacity: 0;
    transition: opacity 0.8s;
}




.ref-title{

    text-align: center;
    text-shadow: 0px 0px 1px #000;
    font-weight: 600;
    letter-spacing: 1px;


 }
 
 .ref-sub-title{
    text-align: center;
    color: #373B3D;
    line-height: 0.5715;
 }
 .res-count{
    color: #0369A1;
    padding :1%;
    font-weight : 600;
 }
 .res-text{
    color: #0369A1;
    
    font-weight : 600;
    
 }

 .Act-container{
 }
 .intituleAr-txt{
    
    text-align: right !important;
    font-weight: 600;
    
 }
 .activiteTextAr{
    text-align: right !important;
 }
 .title-ar-tr{
    line-height: 35px;
 }
.intituleFr-txt{
    color: #252729;
    font-style: normal;
    font-weight: 600;
}

.lbl-txt{

    color : #0369A1;
}
hr{
    background: #acd5e4;
     height: 1px;
     padding-left:30px; 
     padding-right:30px;
     
}

.showInfos-btn{
  background-color: none;
  margin-bottom: 5px;
}
.showInfos-btn{
    background: #0369A1;
    border: solid 1px white;
    border-radius: 13px;
    color: white;
    cursor: pointer;
}

.btn-icn{
    margin-right: 5px;

}
.lanch-btn{
    margin-right: 7px;
    background: #0369A1;
    border: solid 1px #0369A1;
    border-radius: 13px;
    color : white;
    padding: 2px;
    padding-left: 7px;
    padding-right: 7px;
    width: 162px;
}

.btn-container{
    float : right;
}
.sreach-field{
    width :55%;
    border: solid 1px #0369A1;
    border-radius: 13px;
    padding : 4px;
    

}

.input-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    margin-bottom: 20px;
}
.filterBtn{
   
    border: 1px solid #38A4 !important;
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: -206px !important;
 
    background: #0369a1;
    margin-left : 3px;
    height: 33PX;
    width : 33px;
    display: flex;
   align-items: center;
   justify-content: center;
   top: -7px;
    position: relative;
 
}
.typAuto-fld{

    width: 27%;
    margin-left: -3.5%;
    margin-right: 5%;

}
.fa-eye-slash{
    display: none;
}

.auto-compl-result{

 
    display: flex;
    justify-content: center;
    align-items: center;
}
.auto-compl-ul{
    border: solid 1px #38A4;
    width: 53%;
    top: -36px;
    position: relative;
    margin-right: 8%;
    padding: 0;
}
.auto-compl-ul li{

    border-bottom: solid 1px rgb(194, 190, 190);
    padding-top: 7px;
    padding-bottom: 7px;
    list-style: none;
    padding-left : 6px
}
.auto-compl-ul li:hover{
    background: rgba(246, 241, 241, 0.831);
}

.list-nat-ul li:hover{
    background: rgba(246, 241, 241, 0.831);
    cursor: pointer; 
}
.filters-container{
    text-align: center;
}

.pag-btn{

    border : none;
    background: none;
}
.pag-btn:hover{
   background: none;
   color : #0369A1;
   
}
.pageNumber{

    color : #0369A1;
    font-weight: 700;
    margin-left : 10px;
    margin-right: 10px;
}

.pagination{
    text-align: center;
    margin: 2%;
}
.chev-sim-rght{
    margin-right : 10px;
    cursor: pointer;
}

.chev-sim-lft{
    margin-left : 10px;
    cursor: pointer;
}

.detailsAct{
    margin-bottom : 10%;
}

.arabic-title{
    text-align: right;
    font-weight: bold;
}
.intitule-container{
    display: flex;
    justify-content: space-between;
}

.fr-title{
    font-weight: bold;
}
.title-act{
    color : #0369A1;
    width: 115px;
}
.type-aut-nat{
   margin-left: 5px; 
}

.infos-com-title{
    color: #0E5B9B;
    font-size: 15px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
}
.infos-compl-act{
    position: relative;
    margin: 1%;
    padding: 1%;
}
.infos-ref{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap:7px
}

.hr-infcom{
    margin-top: 1
    p;
 
    position: relative;
    top : -4px;
}

.localite  {
    display: flex;
    flex-direction: row; 
   align-items: center;
    
  }

  .loc-child {
    width: 35%;
    margin-bottom: 10px; 
    margin-right : 2%;
   
  }
  .input-with-icon {
    position: relative;
  }
  .icon {
    position: absolute;
    top: 50%;
    right: 10px; 
    transform: translateY(-50%);
    pointer-events: none; 
    color:gray
  }
  .lnch-proc {
    width: 17%;
    margin-left:  2%;
    margin-top: 11px;
  }

 
  .proc-container-child-input{
    width: 72%; 
    margin-right:2%;
  }
  .btn {
    border: 1px solid #118CD1;
    border-radius: 9px;
    color: #fff;
    width: 191px;
    background: #118CD1;
    cursor: pointer;
    display: flex;
    gap: 9px;
    padding: 1px;
  }
  .proc-container-child-btn {
    width: 10%; 
  }
  .proc-container{
    display: flex;
    flex-direction: row; 
   align-items: center;
  }
  .proc-container-child-input input{
    width: 100%;
    border: none;
    padding: 4px;
    font-weight: 600;
  }
  .proc-container-child-btn{
    margin-top: 10px;
    margin-bottom: 10px;

  }
  .showProc-btn{
    width: 76.5%;
  }

  .loc-child-lst{
    width: 35%;
   
  }
  .list-loc-container{
    margin-top: 0px;
    width: 34%;
    margin-right:3.5%;
  }


  .list-loc{
 
    margin-left: 46%;
   

}
.list-loc-ul{
    background: white;
    display: block;
    box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 0 8px 10px 1px rgba(0,0,0,.14), 0 3px 14px 2px rgba(0,0,0,.12);
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    letter-spacing: 0.00938em;
    color: rgba(0, 0, 0, 0.87);
    box-sizing: border-box;
    cursor: text;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 100%;
    border-radius: 4px;
    padding-right: 14px;
}
.list-loc-ul li:hover{
    background: rgba(246, 241, 241, 0.831);
    cursor: pointer; 
}
#icn-proc{
font-size: 30px;
}
.titles-container{
    display: inline-block;
}

.icn-div{
    width : 7%
}
.titl-div{
    width : 83%
}

.proc-ar-title{
    float : right;
}
.titles-container p{

    font-weight: bold;
}
.index-cont{
    color: #118CD1;
font-size: 15px;
font-style: normal;
font-weight: 700;
line-height: 150%;
    
}

.hover-procedure{
    position: absolute;
    top: -21px;
    border: 1px solid #DCEFE7;
    width: 400px;
    color: #00B96B;
    background-color: #DCEFE7;
    border-radius: 5px;
    padding: 2px;
    font-size: 11px;
    display: none;
}
.hover-icon:hover  + .hover-procedure{
    display: inline;
}
.steps-container{
  padding: 10px;
  margin: 10px;
}
.profile-cont-user{
    color: #118CD1;
    font-size: 30px;
}
.profile-cont{
    border : 1px solid #0369A1;
    max-width: 100%;
    width: 100%;
    display: inline-block;
    text-align: center;
    padding : 2px;
    color : #0369A1;
 }
 .profile-cont-adm{
    background-color: #118CD1;
    font-size: 40px;
    border-radius: 40%;
    width: 100%;
}
.profile-cont-ad-m{
    color: #118CD1;
    font-size: 40px;
 }
.doc-desc{
    font-size: 9px;
}
.fa-exclamation-triangle{
    color :rgb(245, 171, 60);
    margin-right : 5px;
}
.title-proc-cont{
    margin-left: 57px;
}
.act-titles-container{
    margin: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}
.div-icn i{
    font-size: 42PX;
    margin-right : 4px
}

.att-act-desc{
    margin-top: -16px;
}
.mssgErr{
    color : red;
}

.showInfos-btn:hover{
    color : white;
}
.fa-file-code{
    color : #9dc4d8;
}
.descr-proc-txt{
    
    margin-right: 5%;
    margin-top: 10px;
    margin-bottom: 10px;
}
.icn-expl{
    float: right;
    font-size: 11px;
    width: 200px;

}
.icn-code{
    color : #9dc4d8;
    margin-left : 3px;
}
.icn-code .fad {
    margin-right :4px;
}
.icn-expl .fa-file-alt{
    margin-right :4px;
}
#mem-com .index-cont {
        position: relative;
        top: -4px;
    }

    .fa-chevron-right:hover{
        background: none;

    }

    .nat-label{
        position: absolute;
        margin-top: -24px;
        font-weight: 500;
        margin-left: -33px;
    }
    .basic-field-ui .css-10botns-MuiInputBase-input-MuiFilledInput-input{
        padding-top: 19px;
    }
    .basic-btn-ui .css-sghohy-MuiButtonBase-root-MuiButton-root {
        padding: 12px 22px !important;
        
       
    }
    .basic-btn-ui{
        text-transform: capitalize !important;
        background: #0369A1 !important;
        letter-spacing : 1px !important;
        padding: 9px 20px !important;
        height : 3.5375em !important;
    }

.box{
        margin-top: 10px;
        margin-bottom: 10px;
        border-radius: 10px;
        background: #FFF;
        box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.10);
    
}

.circle-icon {
    width: 30px;
    height: 30px;
 
    border-radius: 50%; 
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .button-hover:hover{
      cursor: pointer;
  }
.hidden {
    display: none !important;
}
.box-transition{
}
.button-none-button {
    border: none;
    background: none;
    padding: 0;
    margin: 0 15px 0 0;
    cursor: pointer;
    outline: inherit;
}
@media (max-width: 800px) {
    .responsive-web{
        display: none !important;
    }
}

@media (min-width: 801px) {
    .responsive-mobile{
       display: none !important;
    }
}



.size-x{
    font-size: 18px;
}
.size-2x{
    font-size: 2em;
}

.size-3x {
    font-size: 3em;
}

.hide, .searchIcon  {
    display: none; 
}

@media (max-width: 768px) {
    .hide {
        display: inline;
    }

    .searchIcon {
        display: inline;
        color: #b7b9be;
        margin-top: 15px;
    }

    [dir="ltr"] .searchIcon {
        margin-left: 15px;
        margin-right: 0;
    }

    [dir="rtl"] .searchIcon {
        margin-right: 15px;
        margin-left: 0;
    }
}




