.rankingCardContainer{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    border: 1px solid #EAEAEA;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.16);
    padding: 40px 20px;
    border-radius: 8px;
}
.rankingCardIcon{
    display: flex;
}
.rankingCardText{
    display: flex;
    flex-direction: column;
    gap: 40px;
    max-width: 65%;
}
@media (max-width: 800px) {
    .rankingCardContainer{
        flex-direction: column;
        padding: 15px 0 !important;
        border: none !important;
        box-shadow: none !important;
    }
    .rankingCardText{
        display: flex;
        flex-direction: column;
        gap: 40px;
        max-width: 80%;
    }
}
.rankingCardTextTitle{
    color: #0369A1;
    font-weight: 600;
    font-size: 24px;
}
.rankingCardTextDescription{
    font-weight: 500;
    font-size: 16px;
    color:#374151;
    line-height: normal;
}
.rankingCardTextLink{
    font-weight: 600;
    font-size: 16px;
    color: #0369A1;
}
.rankingCardTextLinkContainer{
 display: flex;
    gap: 10px;
}
.pageContentContainer{
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 60px;
    padding: 0 80px;

}
@media (max-width: 801px) {
    .pageContentContainer{
        padding: 0 15px !important;
    }
}

.articleContentContainer{
    min-height: 100vh;
    @apply px-6
}
@media (max-width: 801px) {
    .articleContentContainer{
        @apply !px-1
    }
}
.searchContainer{
    display: flex;
    width: 100%;
    gap: 10px;
    @apply py-3;
}
.searchItemContainer{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.filterContainer{
    display: flex;
    align-items: center;
}
.filterItemsContainer{
    display: flex ;
    align-items: center;
    max-height: 0;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @apply w-11/12 gap-2
}
.filterItemsContainer>div:first-child{
   flex: 2;
}
.filterItemsContainer>div:nth-child(2){
    flex: 3;
}
.filterItemsContainer>div:nth-child(3){
    flex: 2;
}
.filterItemsContainerAppear{
    max-height: 500px !important;
    @apply pt-4 pb-1
}
@media (max-width: 800px) {
    .filterItemsContainer{
        flex-direction: column;
        @apply w-full
    }
}
.notShow {
    visibility: hidden;
    opacity: 0;
}
.table{
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.08),0 1px 4px 0 rgba(69, 75, 87, 0.12), 0 0 0 1px rgba(152, 161, 178, 0.10);
    border-collapse:collapse;
    border-radius: 9px;
    @apply pb-3 my-3;
}

.pageTitle{
    color: #374151;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
    padding: 5px 0;
}
.pageTitleDescription{
    color: #6B7280;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    padding: 5px 0 30px 0;
}
.pageTitleMobile{
    color: #374151;
    font-size: 20px;
    font-weight: 600;
    line-height: 150%;
   @apply pb-6 pt-4 px-3;
}
.activePeriodeText{
    color: #0284C7;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
}
.championContainer{
 display: grid;
}
@media (min-width: 1600px) {
    .championContainer {
        grid-template-columns: repeat(5, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 1300px) {
    .championContainer {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 1001px) and (max-width: 1299px) {
    .championContainer {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .championContainer {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (max-width: 800px) {
    .championContainer {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 25px;
    }
}

.readLine{
    width: 100%;
    height: 1px;
    background: #D1D1D6;
    @apply my-6
}
.articleTitle{
    color:  #374151;
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    @apply mt-12 mb-6
}

.articleBasePolice{
    color: #374151;
    text-align: justify;
    font-size: 16px;
    font-weight: 400;
    line-height: 150.023%;
    @apply px-6
}
.articleStrongTitle{
    color: #374151;
    font-size: 16px;
    font-weight: 600;
    line-height: 150.023%;
    @apply pt-6 px-3;
}
.articleWarningTitle{
    color: #F59E0B;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 150.023%;
    @apply pt-6 flex gap-2 px-4;
}
.articleDropDownTitle{
    color: #0369A1;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 150.023%;
    @apply py-4 flex gap-2 px-4;
}
.articleDropDownDescription{
    transition: all 0.5s ease-in-out;
    max-height: 0;
    overflow: hidden;
}
.appearArticleDropDownDesription{
    opacity: 1;
    max-height: 1000px;
    @apply pt-4 pb-1
}
.radarContainer{
    @apply w-full min-h-[800px] flex justify-center;
}
