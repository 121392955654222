
.breadcrumb{
    margin: 0 30px 0 30px;
}
@media (max-width: 1000px) {
    .breadcrumb{
        display: none;
    }
}

.stepper-web{
    min-height: 0;
    padding: 0;
    display: flex;

}
.stepper-mobile{
        min-height: 0;
        display: flex;
        @apply px-4
    }

.check-ul{
    list-style: none;
}

@media ( max-width : 800px ) {
    .stepper-web {
        display: none;
    }
    .break-line-mobile{
        display: block;
    }
}
@media ( min-width : 801px ) {
    .stepper-mobile {
        display: none;
    }
}
@media ( max-width : 800px ) {
    .hidden-mobile {
        display: none;
    }

}
.global-components-link{
    text-decoration: none;
    color:#0369A1;
    font-size: 18px;
    font-weight: 600;
    line-height: 130%;
    cursor:pointer;
}
.flex-container {
    display: flex;
}

.flex-item {
    padding: 10px;
    margin: 5px;
    height: fit-content;
 
}
.flex-item-center{
    text-align: center;
}
.flex-center{
    display: flex;
    justify-content: center;
}
.flex-vcenter{
    display: flex;
    align-items: center;
}
.start-position-container {
    display: flex;
    align-items: flex-start;
}
.end-position-container {
    display: flex;
    align-items: flex-end;
}
