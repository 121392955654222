@import "https://fonts.googleapis.com/css2?family=Tajawal&family=Inter&family=Rubik&display=swap";
@import url('./fontawesome/pro/css/all.min.css');

ul.ant-menu.ant-menu-sub.ant-menu-vertical{
    display: flex;
    flex-direction: row;
    background-color: #0369A1;
    color: #fff;
    border-radius: 10px;
    padding: 10px 30px;
}

div.ant-menu-item-group-title{
    color: #fff;
    text-transform: uppercase;
    font-size: 15px;
    border-bottom: 1px solid #fff;
    padding: 5px 10px 10px 0;
    width: fit-content;
}
ul.ant-menu.ant-menu-sub.ant-menu-vertical .menuitem{
    font-size: 11px;
}
ul.ant-menu-horizontal{
    border-bottom: none;
}


.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after, .ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after{
    display: none;
}

li>.ant-menu-submenu-title{
    display: flex;
    flex-direction: row-reverse;
    gap: 6px;
}
li>.ant-menu-submenu-title>img{
    min-width: 7px!important;
}

.ant-menu-item-group-list .ant-menu-item, .ant-menu-item-group-list .ant-menu-submenu-title{
    padding: 0 30px 0 0;
}
.ant-menu-vertical .ant-menu-item:not(:last-child), .ant-menu-vertical-left .ant-menu-item:not(:last-child), .ant-menu-vertical-right .ant-menu-item:not(:last-child), .ant-menu-inline .ant-menu-item:not(:last-child){
    margin-top: 0;
    margin-bottom: 0;
}

div.ant-menu-item-group-title{
    margin-bottom: 10px;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-sub.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title{
    padding: 0 24px!important;
    gap: 10px;
    color: #374151;
    font-size: 14px;
}
.ant-menu-sub.ant-menu-inline{
    background: #fff;

}
.ant-menu-submenu-title>.ant-menu-title-content{
    text-transform: uppercase;
    color: #0369A1;
    font-weight: 600;
    font-size: 15px;
}

.ant-menu-item .to-unset-font{
    width: 22px;
}
.ant-tabs-tab-active{
    background: #E0F2FE !important;
    border-radius: 8px 8px 0px 0px !important;
}
.ant-tabs-tab-btn{
    padding: 0 10px !important;
}
.ant-skeleton{
    width: 100% !important;
}


.red-color{
    color: #F87171;
}
.ant-select-focused ,
.ant-select-selector:focus,
.ant-select-selector:active,
 .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
}
.ant-input{
    font-family: Inter, sans-serif !important;
}
.ant-picker-date-panel{
    max-width:400px !important;
    width: fit-content !important;
}
@tailwind base;
@tailwind components;
@tailwind utilities;
