.pageTitle {
    color: #0369A1;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.pageTitleMobile {
    color: #374151;
    text-align: center;
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.pageTitleDescription {
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    padding: 15px 0;
}

.pageTitleContainer {
    padding: 50px 50px 0;
}

.pageTitleContainerMobile {
    padding: 20px 50px 0;
}

.pageContentContainer {
    padding: 20px 30px 40px;
}

.paginationContainer {
    padding: 10px;
    display: flex;
    justify-content: center;
}

.articleContentContainer {
    display: grid;
    padding: 30px 0;
    width: 100%;
}

@media (min-width: 1500px) {
    .articleContentContainer {
        grid-template-columns: repeat(6, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 1300px) {
    .articleContentContainer {
        grid-template-columns: repeat(5, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 1001px) {
    .articleContentContainer {
        grid-template-columns: repeat(4, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 801px) and (max-width: 1000px) {
    .articleContentContainer {
        grid-template-columns: repeat(3, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (min-width: 501px) and (max-width: 800px) {
    .articleContentContainer {
        grid-template-columns: repeat(2, 1fr);
        column-gap: 10px;
        row-gap: 25px;
    }
}

@media (max-width: 500px) {
    .articleContentContainer {
        grid-template-columns: repeat(1, 1fr);
        row-gap: 25px;
    }
}
