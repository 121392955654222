@import "https://fonts.googleapis.com/css2?family=Tajawal&family=Inter&family=Rubik&display=swap";

.rubik{
    font-family: 'Rubik',sans-serif;
}
.semiBoldFR {
    font-family: 'Inter', sans-serif;
    font-weight: 600;
}
.regularFR {
    font-family: 'Inter', sans-serif;
    font-weight: 400;
}
.mediumFR {
    font-family: 'Inter', sans-serif;
    font-weight: 500;
}
.boldFR{
    font-family: 'Inter', sans-serif;
    font-weight: 700;
}
.extraBoldAR {
    font-family: 'Tajawal', sans-serif;
    font-weight: 600;
}
.regularAR {
    font-family: 'Tajawal', sans-serif;
    font-weight: 400;
}
.mediumAR {
    font-family: 'Tajawal', sans-serif;
    font-weight: 500;
}
.boldAR {
    font-family: 'Tajawal', sans-serif;
    font-weight: 700;
}
.notShow {
    visibility: hidden;
    opacity: 0;
}
.show {
    visibility: visible;
    opacity: 1;
}

.notDisplay{
    visibility: hidden;
    opacity: 0;
    max-height: 0;
}
.notDisplayV2{
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    position: fixed;
    overflow: auto;
}

.animateZigZag :global{
    -webkit-animation-name: tilt-shaking;
    -webkit-animation-duration: 0.65s;
    -webkit-animation-timing-function: linear;
}

@keyframes :global(tilt-shaking) {
    0% { transform: translateX(0) }
    25% { transform: translateX(10px) }
    50% { transform: translateX(-10px) }
    75% { transform: translateX(10px) }
    100% { transform: translateX(0) }
}