.articleSearchInput{
    border: 1px solid #6B7280;
    /* border: 1px solid #0369A1; */
   background: #FFF;
    align-items: center;
    padding: 0px 40px;
    width: 100%;
    outline: none;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    transition: all .2s ease;
}
.rtlSearchInput{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
   /*  border-radius: 8px; */
}
.ltrSearchInput{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    /* border-radius: 8px; */
}
.openMenuBorderRadius{
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
}
.openMenuBorder{
    border: 1px solid #0369A1 !important;
    border-bottom: 1px dotted #0369A1 !important;
}

.articleSearchInput:placeholder-shown {
    text-overflow: ellipsis;
}
.articleSearchInput:focus{
    border: 1px solid #0369A1;
}
.articleSearchButton{
    background: #0369A1;
    padding: 15px;
    padding-right: 0;
    margin: auto 0; 
    cursor: pointer;
}
.rtlSearchButton{
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.ltrSearchButton{
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
.articleSearchContainer{
    display: flex;
    justify-content: center;
    width: 100%;
}
.whiteColor{
    color:white;
}
.searchMenu{
    opacity: 1;
    position: absolute;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background: #fff;
    line-height: 250%;
    max-width: 100vw;
    width: 99.9%;
    border: 1px solid  #0369A1;
    border-top: none;
    z-index: 3;
    transition: opacity 0.2s ease-in;
}

.searchMenuItem{
    cursor: pointer;
    @apply px-4 py-2
}
  .searchMenuItem:hover{
background: #E3F2FD;
}
.notDisplay{
    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in;
}


.filterSelectContainer {
    border: 1px solid #0369A1 !important;
    height: auto;
    width: 50%;
    transition: border-color 0.3s ease;
   @apply flex justify-center items-center
}

@media (max-width: 768px) {

    .filterSelectContainer {
        display: none;
    }

    .articleSearchButton {
        display: none;
    }

    .ltrSearchInput, .rtlSearchInput{
       border: none;
       margin: 10px 0 0 0;
       padding: 0 25px;
    }

    .articleSearchInput:focus{
        border:none
    }

    .openMenuBorder {
        border: none !important;
    }

    .searchMenu {
        width: 295.5px !important;
        margin-left: -42px;
    }

    .searchMenu {
        width: 295.5px !important;
        margin-left: -42px;
    }

    [dir="rtl"] .searchMenu {
        width: 295.5px !important;
        margin-left: 0;
        margin-right: -42px;
    }

}
