.filterButton{
    width: fit-content;
    padding: 15px;
    border-radius: 5px;
   border: 1px solid  #0369A1;
    height: fit-content;
    display: flex;
    align-items: center;
    margin-left: 20px;
}
.activeFilterButton{
    background-color: #0369A1;
    color: #fff;
}
.nonActiveFilterButton{
    background-color:#fff;
    color: #0369A1 ;
}

@media (max-width: 768px) {
    .filterButton {
        border: none;
        margin: 0;
    }
    .activeFilterButton{
        background-color: #fff;
        color: #0369A1 ;
    }
    .nonActiveFilterButton{
        background-color:#fff;
        color: #3C4D64;
    }
}
