.championTreeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid #EAEAEA;
    background: #FFF;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.16);
    width: inherit;
    @apply px-8 py-8;
}
.championTreeTitle{
    color:  #374151;
    font-size: 20px;
    font-weight: 600;
    @apply mx-auto;
}
.barsContainer{
@apply flex items-end justify-around gap-1 h-[300px] w-full;
    max-width: 70%;
}
.ranksContainer{
    @apply flex justify-around items-start gap-1 w-full;
    max-width: 70%;
}
.bar{
    border-radius: 8px;
    display: flex;
    justify-content: center;
    transition: height 0.3s ease-in-out;
}
.firstBar {
    width: 100%;
    background: #0284C7;
}
.secondBar{
    width: 100%;
    background:  #075985;

}
.thirdBar{
    width: 100%;
    background:  #0EA5E9;
}
.barText{
    writing-mode: vertical-rl;
    color: white;
    font-size: 14px;
    font-weight: 400;
    transition: all 1s linear;
    opacity: 1;
    overflow:auto;
    @apply py-4;
}
.barTextFr{
    transform: rotate(180deg);
}
.barTextHidden{
    opacity: 0;
    max-width: 0;
}
.rank{
    @apply flex justify-center w-1/12;
    color: #4F4F4F;
    font-size: 10px;
    font-weight: 700;
}
.barContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
}
.chartContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
}
.barValuesContainer{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

}
.chartBar{
    border-bottom-right-radius: 8px;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    transition: width 0.3s ease-in-out;
    width: 0px;
    @apply p-3;
}
.chartBarAr{
    border-bottom-left-radius: 8px !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    transition: width 0.3s ease-in-out;
    width: 0px;
    @apply p-3;
}
.barValue{
    color: var(--Gray-700, #374151);
    text-align: center;
    font-size: 12px;
    font-weight: 700;
    @apply p-3.5;
}
.barChartContainer{
    display: flex;
    gap: 5px;
    width: auto;
}