.card-container{
    width: 90%;
    margin: 0 auto;
    border-radius: 15px;
    box-shadow: 4px 4px 4px 4px rgba(240, 240, 240, 0.50);
}
.card-content-container{
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0px 20px 20px;
    margin-top:15px;
}
.card-image-container{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
}
.card-image-item{
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 100%;
    max-height: 190px;
}
.card-title-item{
    color: #4B5563;
    font-size: 16px;
    font-weight: 600;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    height: 41px;
    cursor: pointer;

}
.card-description-item{
      color: #6B7280;
      font-size: 14px;
      font-weight: 400;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    display: -webkit-box;
    height: 80px;

}

.card-title-item:hover{
    text-decoration: underline;
}