.pageTitle {
    color: #0369A1;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
}

.pageTitleMobile {
    display: flex;
    width: 100%;
    padding: 5px 0;
    gap:5px;
    /* align-items: center;
    justify-content: center; */
    color: #374151;
    /* text-align: center; */
    font-size: 26px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0 auto;
}
.pageTitleMobileItem {
    /* text-align: center; */
    text-decoration: none;
    margin: 0 ;
    /* padding: 1px 0; */
    padding: 0;
    display: inline;
    line-height: 1;
    /* vertical-align: middle; */
}

.pageTitleDescription {
    color: #6B7280;
    font-size: 14px;
    font-weight: 400;
    line-height: 150%;
    @apply py-4
}

.pageTitleContainer {
    padding: 50px 50px 0;
    /*padding: 0; */
}

.pageTitleContainerMobile {
    padding: 20px 30px 0;
    /* padding: 0; */
    
}
.pageContentContainer {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}
@media(min-width: 801px){
    .pageContentContainer {
        position: relative;
        overflow: hidden;
        padding: 20px 70px 40px;
        min-height: 100vh;
    }
}
@media (max-width: 800px) {
    .pageContentContainer {
        padding: 0px 20px 100px;
    }
}
.blueColor{
    color : #0369A1
}
@media (max-width: 800px) {
    .responsiveWeb{
        display: none !important;
    }
}

@media (min-width: 801px) {
    .responsiveMobile{
        display: none !important;
    }
}
.emptyResultContainer{
    display: flex;
    justify-content: center;
    @apply py-16

}
