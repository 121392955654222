.toggle-button-container {
  display: flex;
  background-color: #e0efff;
  border-radius: 8px;
  overflow: hidden;
  /* Ensure the container works for both LTR and RTL directions */
  direction: ltr; /* Default to LTR */
}

/* Add media query to handle smaller screens */
@media (max-width: 768px) {
  .toggle-button-container {
    width: 77%;
  }
}

/* Handle RTL layout when the page is in Arabic or other RTL languages */
html[dir="rtl"] .toggle-button-container {
  direction: rtl; /* Switch to RTL */
  /* If necessary, adjust layout styles here for RTL compatibility */
}


.toggle-button {
  flex: 1; 
  padding: 10px;
  border: none;
  background-color: transparent;
  color: #555;
  font-family: Tajawal;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

/* Make the 'Partiellement couverte' button larger */
.partiellement-large {
  WIDTH: 290px; /* Adjust this value to increase the size */
}

.filter-container{
  font-family: 'Inter, sans-serif';
  position: absolute;
  left: 60px;
  top: 10px;
  padding: 10px;
  border-radius: 8px;
  z-index: 1000;
}
@media (max-width: 768px) {
  .filter-container {
    width: 99%;
    left:47px;
  }
}
.mapContainerFirst{
  margin: 0 5% 0 5%;
  width: 90%;
  height: 700px;
  display: "flex";
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
}.mapContainerFirst {
  margin: 0 5% 0 5%;
  width: 90%;
  height: 700px;
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
}

@media (max-width: 768px) {
  .mapContainerFirst {
    width: 90%;
    height: 70%;
    margin: 0px 5% 0 5%;
  }
}

@media (min-width: 1200px) {
  .mapContainerFirst {
    width: 85%;  
    height: 800px;  
    margin: 0 10% 0 10%;  
  }
}

@media (max-width: 768px) {
  .mapContainerFirst {
    width: 90%;
    height: 70%;
    margin: 0px 5% 0 5%;
  }
}


/* Styles for selected buttons */
.toggle-button.selected-couverte {
  background-color: #4caf50;
  color: white;
}

.toggle-button.selected-partiellement-couverte {
  background-color: #f59e0b;
  color: white;
}

.toggle-button.selected-non-couverte {
  background-color: #757575;
  color: white;
}

/* Intensify background color on hover for selected buttons */
.toggle-button.selected-couverte:hover {
  background-color: #388e3c;
}

.toggle-button.selected-partiellement-couverte:hover {
  background-color: #d38e0b;
}

.toggle-button.selected-non-couverte:hover {
  background-color: #5a5a5a;
}

/* Hover effect for non-selected buttons */
/* .toggle-button:not(.selected-couverte):hover,
.toggle-button:not(.selected-partiellement-couverte):hover,
.toggle-button:not(.selected-non-couverte):hover {
  background-color: #f0f0f0;
} */

.toggle-button:first-of-type {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.toggle-button:last-of-type {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}
.ECOInfos6Y {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
}

/* Override for RTL languages */
[dir="rtl"] .ECOInfos6Y {
  justify-content: flex-start; /* Aligns items to the start (right side in RTL) */
}

.EcoStatusY5 {
  font-family: Tajawal;
  font-size: 13px;
  font-weight: 400;
  line-height: 22px;
  text-align: left;
}

[dir="rtl"] .EcoStatusY5 {
  text-align: right;
}

.EcoStatusY8 {
  font-family: Tajawal;
  font-size: 14px;
  font-weight: 700;
  line-height: 22px;
  width: 70%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  text-align: left;
}

/* For languages with RTL direction, such as Arabic */
[dir="rtl"] .EcoStatusY8 {
  text-align: right;
}

/* Hover effect for both LTR and RTL */
.EcoStatusY8:hover {
  white-space: normal;
}

.leaflet-popup-content-wrapper {
  background-color: #ffffff; 
  opacity: 1 !important; 
}
/* For RTL pages, flip the map container */
html[dir="rtl"] .leaflet-container {
  transform: scaleX(-1); /* Flip the map horizontally */
  direction: rtl; /* Ensure the map layout is right-to-left */
}

/* Optional: Flip the controls and zoom buttons (if needed) */
html[dir="rtl"] .leaflet-control-zoom {
  transform: scaleX(-1);
}

/* Optional: Flip the map attribution if needed */
html[dir="rtl"] .leaflet-control-attribution {
  transform: scaleX(-1);
}
.cadrePopup{
  margin-top: 13PX;

}
.containerstylesearch{
  padding: 50PX 50PX 0PX;
  @media (max-width: 768px) {
    padding: 10PX !important;
    }
}
.pageTitle {
  color: #0369A1;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  @media (max-width: 768px) {
  color: #374151;
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
}

.pageTitleDescription {
  display: flex;
  color: #6B7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  @apply py-4;
  @media (max-width: 768px) {
    justify-content: center;
    color: #374151;
    font-family: Tajawal;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 24.2px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}

.descriptionContent{
  @media (max-width: 768px) {
    width: 311px;
    text-align: center;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}
