.c-tag-container{
    display: inline-flex;
    padding: 1% 2%;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background: #0EA5E9;
    color:  #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}

.swiper-container {
 display: flex;
 scrollbar-width: thin;
scrollbar-color: transparent transparent;
}

div::-webkit-scrollbar {
    width: 0px;
}

div::-webkit-scrollbar-thumb {
    background-color: transparent;
}
.swiper-wrapper {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    position: relative;
    width: 100%;
}

.swiper-slides {
    flex: 0 0 calc(100% / 7);
    height: 100%;
    position: relative;
    transition-property: transform;
    display: block;
}

.swiper-button-prev,
.swiper-button-next {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.swiper-button-prev {
    left: 0;
}

.swiper-button-next {
    right: 0;
}
