.formContainer{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    margin: 0 100px 50px 100px;
    border-radius: 8px;
}
.container{
    padding: 1% 4%;
}
.fomGenerlInfo{
    display: flex;
    flex-direction: row;
    gap: 20px;
}
.headerPropAct {
    color: #3388AA;
}
.btnProp{
    border-radius: 7px;
    border: 0 ;
    background:  #16A34A;;
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: 80%;
    transition: all 0.5s ease;
    margin: 0 auto;
    @apply px-4 py-3
}
.btnProp:hover{
    cursor: pointer;
    transform:scale(1.02);
    background:#16A34A;;

}
.lineProp {
    width: 28%;
    height: 1.25px;
    background-color: #EAEAEA;
    margin-bottom: 3%;
}

@media (max-width: 700px)  {
    .pageTitleItemMobile {
        line-height: 1.5;
        text-align: center;
       
    }
    .pageTitleMobileContainer{
        align-items: unset;
        justify-content: center;
        display: flex;
    }
    .pageTitleDescriptionMobile{
        padding: 5PX;
         text-align: justify;
         font-size: 15px;
    
    }
  .pageTitleContainerMobile{
        color: #374151 !important;
        display: flex;
        justify-content: center;
    }
    .boxborder{
        margin-left: 2.5rem;
        margin-right: 2.5rem;
    }
    .container{

        padding: 0px 0px;
    }
    .lineProp{
        width: 100%;
        background: #dce0e8;
        margin-top: -1%;
    }
    .gridContainer{
        display: grid;
    }
    .gridContainerSelect{
        display: grid;
    }
    .btnWith{
        width: 100%;
    }
    .breadcrumb{
        display:none !important;
    }
}

@media (min-width: 701px)  {
    .gridContainer{
        display: grid;
        grid-template-columns: repeat(2, 1fr); 
        gap: 0.5rem;
    }

    .gridContainerSelect{
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 0.5rem;
    }
    .btnWith{
        width: 16.666667%; 
    }
   
    .boxborder{
        border: 0.1px solid hsl(0, 11%, 95%);
        @apply sm:shadow-lg mx-20 my-3 rounded-md
    }
}
/*
@media (max-width: 1000px) {
    .responsiveWeb{
        display: none !important;
    }
}

*/

@media (max-width: 700px) {
    .responsiveWeb{
        display: none !important;
    }
}

@media (min-width: 701px) {
    .responsiveMobile{
       display: none !important;
    }
}