.loaderContainer{
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: #FFFFFF;
    z-index:2000;
    overflow: hidden;
}
.loaderItemContainer :global{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background: #fff;
    width: 100px;
    padding: 30px;
   box-shadow:  1px 1px 1px 1px rgba(244, 244, 244, 0.91);
    border: 5px solid #FFFFFF;
    border-top: 5px solid #347b9b;
    animation: spin 1s ease-out infinite;
}
.loaderImg :global{
    border-radius: 50%;
    width: 100%;
    animation: spin-reverse 1s ease-out infinite;
}
@keyframes :global(spin) {
    0%   { -webkit-transform: rotate(   0deg); }
    100% { -webkit-transform: rotate(360deg); }
}
@keyframes :global(spin-reverse) {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(-360deg); }
}
.notDisplay{
    display: none;
}
.html {
    overflow: hidden;
    overflow-x: hidden;
}
