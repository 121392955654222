.firstRowColor{
    background: #FFFFFF;
}
.secondRowColor{
    background: #FFFFFF;
}
@media (min-width: 801px) {
.secondRowColor{
    background: #F5FBFF !important;
}
}