.mapp {
  width: 99%;
  height: 70%;
  z-index: 0;
  position: relative;
}
.pageTitle {
  color: #0369A1;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  @media (max-width: 768px) {
  color: #374151;
  display: flex;
  justify-content: center;
  font-size: 26px;
  font-weight: 700;
  line-height: 24.2px;
  text-align: left;
  text-underline-position: from-font;
  text-decoration-skip-ink: none;
  }
}

.pageTitleDescription {
  display: flex;
  color: #6B7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  @apply py-4;
  @media (max-width: 768px) {
    justify-content: center;
    color: #374151;
    font-family: Tajawal;
    font-family: Inter;
font-size: 20px;
font-weight: 700;
line-height: 24.2px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}

.descriptionContent{
  
  @media (max-width: 768px) {
    width: 311px;
    text-align: center;
    color: #6B7280;
font-family: Inter;
font-size: 16px;
font-weight: 400;
line-height: 24px;
text-align: center;
text-underline-position: from-font;
text-decoration-skip-ink: none;

    }
}
.containerstylesearch{
  padding: 50PX 50PX 0PX;
  @media (max-width: 768px) {
    padding: 10PX !important;
    }
}
.mapp {
  width: 99%;
  height: 80%;
  z-index: 0;
  position: relative;
  background: linear-gradient(135deg, #e0f7fa, #b2ebf2, #80deea);
  border-radius: 12px;
  /* box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1), 0 2px 4px rgba(0, 0, 0, 0.08); */
  border: 1px solid rgba(0, 0, 0, 0.08);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}



.mapp:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
  /* Subtle overlay to enhance text legibility or provide a stylish effect */
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(3px); /* Softens the background slightly */
  
  border-radius: 12px; /* Maintain rounded corners */
  pointer-events: none; /* Overlay should not affect interactions */
}

/*filter item*/
.searchItemContainer{
  display: flex;
  flex-direction: column;
  width: 100%;
}
.filterContainer{
  display: flex;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 10px;
   }

}
.pageTitleContainer {
  padding: 50px 50px 0;
  /*padding: 0; */
}

.searchContainer{
  display: flex;
  width: 100%;
  gap: 10px;
  @apply py-3;
  @media (max-width: 768px) {
   border: 1px solid  #6B7280;
   border-radius: 6px;
   margin-bottom: 10px;
   gap: 0px !important;
  }

}
.searchIcon{
  display: none;
  @media (max-width: 768px) {
    width: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    
   }
}
.notShow {
  visibility: hidden;
  opacity: 0;
}
.filterItemsContainer{
  display: flex ;
  align-items: center;
  max-height: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  gap: 11px;
   /* Mobile Styles */
   @media (max-width: 768px) {
    flex-direction: column;
    justify-content: flex-start;
    gap: 0px;
  }
}
.filterItemsContainerAppear{
  max-height: 500px !important;
  @apply pt-4 pb-1
}

.customborderwrapper {
  position: relative;
  display: inline-block;
  padding-bottom: 20px;
  @media (max-width: 768px) {
    width: 100%;
   }
}
.customlabel {
  position: absolute;
  top: -10px;
  left:5px;
  padding: 0 5px;
  @apply mx-2 px-2 z-10 w-fit bg-white;
  color:rgba(34, 51, 84, 0.50);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  transition: all 0.1s ease;
}
.customlabel.rtl {
  left: auto;
}
.customlabel.ltr {
  right: auto;
}
.customborder {
  border: 1px solid #9CA3AF;
  padding: 12px; 
  border-radius: 5px; 
  @media (max-width: 768px) {
   width: 92%;
  }
}

