
.max-content{
    min-height:  88vh;
}
.ed-res-title{
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
.ed-res-txt{
    color: #374151;
    text-align: center;
    font-family: Rubik,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%;
}
.ed-reference{
    color: #0369A1;
    font-family: Rubik,sans-serif;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;

}
.ed-request-form-container{
    display: flex;
    align-items: flex-start;
    gap: 10px;
    margin: 5% 0;
}
@media (max-width: 1000px){
    .ed-request-form-container{
        flex-direction: column;

    }
}

@media  (min-width: 900px) {
    .ed-container{
        border-radius: 12px;
        border: 1px solid #EAEAEA;
        box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.06);
        @apply mx-12 py-6 px-4
    }
}
.flex-column{
    display: flex;
    flex-direction: column;
}
.vh-flex-center{
    align-content: center;
    justify-content: center;
}
.ed-message{
    color: #0369A1;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
}
.ed-modal-close-btn{
    border-radius: 7px;
    border: 0 ;
    background: #F87171;
    color: #FFF;
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    width: fit-content;
    transition: all 0.5s ease;
    margin: 0 auto;
    @apply px-4 py-3
}
.ed-modal-close-btn:hover{
    cursor: pointer;
    transform:scale(1.02);
    background: #993232;

}
.ed-modal-message{
    color:  #9CA3AF;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    max-width: 400px;
    margin: 0px auto;
}
.ed-modal-title{
    color:  #374151;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 150%;
}
.flex-item-search-bar{
    display: flex;
    padding: 10px;
    margin: 5px;
    height: 50vh;
    align-items: center;
    flex-grow: 1;
}

input::placeholder {
    color: #9CA3AF;

}
.ed-request-title{
        line-height: 0.8;
        font-size: 24px;
        cursor: pointer;
}
.ed-request-title-mobile{
    line-height: 0.8;
    font-size: 26px;
    color: #374151;
    text-align: center;

}
@media(max-width: 500px){
 .ed-request-title-mobile{
   display: flex;
    }
}
@media(min-width: 501px){
    .ed-request-title-mobile{
      display: none;
       }
   }

.arrow_icon{
  margin: auto 15px;
  color: #374151;
}
@media(max-width: 501px){
    .ed-request-title{
        color: #0369A1;
    }

}
@media(max-width: 500px){
    .ed-request-title{
        color: #374151;

    }
    
}

.search-wrapper {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    margin-bottom: 2%;
    background: #ffff;
}

.search-icon {
    margin-right: 0.5rem;
    color: #888;
}

.search-input {
    border: none;
    outline: none;
    width: 100%;
    padding: 0.5rem;
}
.container-box {
    background-color: #f5f9fc;
    border: 1px solid #f5f9fc; 
    border-radius: 8px; 
    box-shadow: 0 2px 4px #f5f9fc;
    padding: 20px; 
    margin: 20px auto; 
    max-width: 1000px; 
}

.intro-paragraph {
    font-size: 16px; 
    color: #555; 
    margin-bottom: 20px;
    text-align: center;
    display: flex;

}

.intro-icon {
    margin-right: 8px; 
    font-size: 18px; 
}
.folderNotSelect{
    display: flex;
    align-items: center;
    justify-content: center;
}
.search-type-selector{
    font-size: 12px;
}
.input-position{
    position : relative;
    top : 3px;
}
.search-type-selector-flex{
    display: flex;
    flex-wrap: wrap;

}
.label-flex{
    white-space: nowrap;
}

@media (min-width: 501PX)    {
    .breadcrumb {
        display: flex !important;
    }
}