.pageTitle {
  color: #0369a1;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  margin-bottom: 3%;
}

.pageTitleMobile {
  width: 100%;
  padding: 5px 0;
  align-items: center;
  /* justify-content: center; */
  color: #374151;
  /* text-align: center; */
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin: 0 auto 0 0;
  margin-left: -15px;
  @apply flex gap-6;
}
.pageTitleMobileItem {
  /*  text-align: center; */
  text-decoration: none;
  margin: 0;
  padding: 1px 0;
  display: inline;
  line-height: 1;
  /* vertical-align: middle; */
}

.pageTitleDescription {
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
  padding: 25px 0;
}

.pageTitleContainer {
  padding: 50px 50px 0;
}

.pageTitleContainerMobile {
  padding: 20px 20px 0;
}

.pageContentContainer {
  position: relative;
  overflow: hidden;
  min-height: 100vh;
}
@media (min-width: 801px) {
  .pageContentContainer {
    position: relative;
    overflow: hidden;
    padding: 20px 70px 40px;
    min-height: 100vh;
  }
}
@media (max-width: 800px) {
  .pageContentContainer {
    padding: 0px 20px 100px;
  }
}

@media (max-width: 700px) {
  .pageTitleMobile {
    margin-left: auto;
  }
}
.cardTitle {
  color: #3c4d64;
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  max-width: 200px;
}

.cardContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background: #fff;
  border: 1px solid #f3f4f6;
  cursor: pointer;
}
.cardContentContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
@media (min-width: 801px) {
  .cardContainer:hover {
    border: 1px solid #0369a1 !important;
  }
}
@media (max-width: 800px) {
  .cardContainer {
    border: 0.3px solid #0369a1 !important;
    padding: 20px 0;
  }
  .cardContainer:hover {
    border: 0.3px solid #64b5f6 !important;
  }
}

.repositoryCardsContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 30px 0;
  column-gap: 25px;
}
@media (max-width: 600px) {
  .repositoryCardsContainer {
    grid-template-columns: repeat(1, 1fr);
    row-gap: 25px;
    padding: 0px 10px;
  }
}
@media (min-width: 601px) and (max-width: 1000px) {
  .repositoryCardsContainer {
    grid-template-columns: repeat(2, 1fr);
    row-gap: 25px;
    padding: 0px 10px;
  }
}
.repositoryExampleContainer {
  display: flex;
  flex-direction: column;
}
@media (max-width: 800px) {
  .repositoryExampleContainer {
    padding: 0 15px;
  }
}
.repositoryExampleLabel {
  color: #4b5563;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.repositoryExampleItems {
  color: #4b5563;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.authorizationListTagsContainer {
  display: flex;
  gap: 15px;
  align-items: center;
}
.tagsListTitle {
  color: #6b7280;
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
.tagsListMessage {
  color: #4b5563;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin: 0 auto;
  padding: 10px 0 20px;
}
.tagsListLink {
  color: #0369a1;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  cursor: pointer;
  text-decoration-line: underline;
}
.filterContentContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 30px 0;
  column-gap: 15px;
  row-gap: 35px;
}
.activityCardContainer {
  display: flex;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  padding: 24px 66px 30px 18px;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .activityCardContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px 15px;
    gap: 10px !important;
  }
}
.activityCardSkeletonContainer {
  display: flex;
  gap: 15px;
  border-radius: 8px;
  border: 1px solid #eaeaea;
  background: #fff;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  padding: 10px 60px 15px 15px;
  justify-content: space-between;
}
@media (max-width: 800px) {
  .activityCardSkeletonContainer {
    flex-direction: column;
    padding: 20px 15px;
    gap: 20px !important;
  }
}
.activityCardTitle {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  white-space: pre-line;
  margin: 0;
}
@media (max-width: 801px) {
  .activityCardTitle {
    color: #374151 !important;
  }
}
.activityCardDescription {
  color: #9ca3af;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%;
}
.activityContentContainer {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.activityCardText {
  display: flex;
  flex-direction: column;
  gap: 15px;
  flex: 3;
}

.tagItem {
  @apply text-white bg-primary rounded-md text-sm px-2 py-1 mx-4 text-nowrap;
}

@media (min-width: 801px) {
  .activityCardText {
    max-width: 75%;
  }
}
.activityCardButton {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 800px) {
  .activityCardBtn {
    background-color: #f5fbff !important;
    padding: 10px 0 10px 0;
    width: 200px;
  }
}
.paginationContainer {
  padding: 30px 0;
  display: flex;
  justify-content: center;
}

.searchContainer {
  display: flex;
  width: 100%;
  gap: 10px;
}
.filterContainer {
  display: flex;
  align-items: center;
}
.filterItemsContainer {
  display: flex;
  max-height: 0;
  opacity: 1;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  @apply w-3/4 gap-2;
}
.filterItemsContainer > div:first-child {
  flex: 2;
}
.filterItemsContainer > div:last-child {
  flex: 3;
}
.localiteFilterContainer {
  display: flex;
  gap: 10px;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}

.filterItemsContainerAppear {
  max-height: 500px;
  @apply py-3;
}
@media (min-width: 801px) {
  .localiteFilterContainer {
    max-width: 75%;
  }
  .localiteFilterContainer > div:first-child {
    flex: 1;
  }
  .localiteFilterContainer > div:nth-child(2) {
    flex: 1;
  }
}
@media (max-width: 800px) {
  .localiteFilterContainer {
    flex-direction: column;
  }
  .filterItemsContainer {
    flex-direction: column;
    @apply w-full;
  }
  .filterItemsContainerAppear {
    max-height: 500px;
  }
}
.detailActivityTilte {
  color: #4b5563;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
  padding: 20px 0 0;
}
.detailActivityDescription {
  color: #6b7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  padding: 10px;
}
@media (min-width: 801px) {
  max-width: 70%;
}
.detailActivityTitleContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.detailActivityParagraphTitle {
  color: #4b5563;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 150%;
}
.detailActivityParagraphDescription {
  color: #373b3d;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
.detailActivityParagraphContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 15px 0 0;
}
.detailActivityTabItemContainer {
  display: flex;
  gap: 10px;
}
.detailActivityTabItemsContainer {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.detailActivityTabItemTitle {
  color: #0369a1;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%;
}
.detailActivityTabItemDescription {
  color: #374151;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
}
.detailActivityTabItemIconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
.detailActivityTabItemTextContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.detailActivityTabItemIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #0369a1;
  color: #ffffff;
  padding: 10px;
  width: 20px;
  height: 20px;
  border-radius: 50px;
}
.notDisplay {
  visibility: hidden;
  opacity: 0;
}
.notShow {
  visibility: hidden;
  opacity: 0;
}
.pageContent {
  position: absolute;
  transition: all 0.3s ease-in;
  top: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
}
@media (min-width: 801px) {
  .pageContent {
    gap: 15px !important;
  }
}
.displayPageContent {
  position: sticky !important;
  z-index: 5;
  opacity: 1;
}
.activityError {
  color: #f87171;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  z-index: 4;
}
.filterloader {
  display: flex;
  align-items: center;
}
.searchItemContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.activityTag {
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.SelectMobileVersion {
  display: none;
}

@media (max-width: 768px) {
  .SelectMobileVersion {
    border: 1px solid #0369a1 !important;
    height: auto;
    border-radius: 3px;
    padding: 5px 0;
    width: 100%;
    transition: border-color 0.3s ease;
    @apply flex justify-center items-center;
  }
  .btnContent {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2px;
  }

  .searchContainer {
    border: 1px solid #6b7280;
    border-radius: 4px;
  }
}
